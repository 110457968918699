import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import store from './store'
import './api/mock'
import Cookies from 'js-cookie'
import './style/index.css'
import querystring from "query-string"
Vue.use(ElementUI)

Vue.prototype.$querystring = querystring
Vue.config.productionTip = false






new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')