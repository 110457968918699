<template>
    <div class="container">
        <div class="logo cleftmargin">

        </div>
        <div class="cjtitle cleftmargin">
            忘记密码
        </div>
        <div class="yyaccount">
            想起密码？<span class="ljlogin" title="马上登录" @click="gotologin">马上登录</span>
        </div>
        <div class="regformpwd">

            <el-form ref="form"  :rules="rules" class="formstyle" label-position="top"  :model="form" label-width="30%">
                <el-form-item label="手机号" class="mttop labelstyle" prop="mobilephone">
                    <el-input class="inputstyle heightstyle"  v-model="form.mobilephone" clearable></el-input>
                </el-form-item>
                <el-form-item label="验证码" class="mttop labelstyle" prop="checkcode">
                    <el-input class="checkcodes"  v-model="form.checkcode" clearable></el-input>
                    <el-button  class="sendmsgcode" style="background-color: #42B134;color:#FFFFFF;border:1px solid #42B134;"
                                @click="getCode"
                                :disabled="!login.canGet"
                                type="button">
                        <span v-show="!login.canGet">{{login.waitTime+"s"}}后重发</span>
                        <span v-show="login.canGet">{{tempLogin.text}}</span>
                    </el-button>
                </el-form-item>
                <el-form-item label="新密码"  class="mttop labelstyle"  prop="pwd">
                    <el-input  show-password class="inputstyle"  v-model="form.pwd" clearable></el-input>
                </el-form-item>
                <el-form-item label="确认新密码"  class="mttop labelstyle"  prop="confirmpwd">
                    <el-input  show-password class="inputstyle"  v-model="form.confirmpwd" clearable></el-input>
                </el-form-item>
                <el-form-item >
                    <el-button class="forgetpwdbtn" @click="submitforgetpwd" style="background-color: #42B134;color:#FFFFFF;border:1px solid #42B134;" title="确定">确定</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>
<script>
    import Cookie from 'js-cookie';
    import { forgetpwd,smsCode } from '../api/index';
    export default {
        components: {

        },
        watch: {

        },
        created () {

        },
        data () {
            return {
                tempLogin: { // 定义一个临时对象
                    canGet: true,
                    timer: null,
                    waitTime: 60,
                    text: '发送验证码'
                },
                form: {
                    mobilephone: "",
                    checkcode: "",
                    pwd:"",
                    confirmpwd:"",
                },
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],
                    checkcode: [
                        { required: true, message: '请输入验证码', trigger: 'blur' },
                    ],
                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],
                    confirmpwd:[
                        { required: true, message: '请输入确认新密码', trigger: 'blur' },
                    ],

                }
            }
        },
        computed: {
            login () { // 最终对象
                const _this = this
                if (!this.tempLogin.canGet) {
                    if (this.tempLogin.waitTime === 0) {
                        _this.tempLogin.text = '重新发送'
                    }
                    return this.timeCountdown(this.tempLogin)
                } else {
                    return this.tempLogin
                }
            },
        },
        methods: {
            //忘记密码
            submitforgetpwd(){
                var mobilephonestr=this.form.mobilephone;
                var checkcodestr=this.form.checkcode;
                var newspwd=this.form.pwd;
                var confirmpwd=this.form.confirmpwd;
                this.$refs["form"].validate((val) => {
                    if (val) {
                        if(newspwd!=confirmpwd){
                            this.$message.error('新密码和确认密码不一致');
                            return;
                        }
                        const params = {
                            mobilePhone: mobilephonestr,
                            memPwd: newspwd,
                            checkCode:checkcodestr,
                            sendType:'2'
                        }
                        //const p = this.$querystring.stringify(params);
                        forgetpwd(params).then(res => {
                            if (res.data.code && res.data.code === 200) {
                            this.$message.success('找回密码成功');
                            //this.$emit('loadingFunt', false);
                            this.$router.push('/login');
                        }else{
                            var  msgstr=res.data.msg;
                            this.$message.error('找回密码失败:'+msgstr);
                            //this.$emit('loadingFunt', false);

                        }
                    })
                    } else {
                        return false;
            }
            });
            },
            //转向登录
            gotologin(){
                this.$router.push('/login');
            },
            //发送短信验证码
            getCode(){
                var  mobilephonestr=this.form.mobilephone;
                if(""==mobilephonestr || null==mobilephonestr){
                    this.$message.warning('请输入手机号！');
                    return;
                }
                const params = {
                    mobilePhone: mobilephonestr,
                    sendType:'2'
                }
                //调用短信发送接口

                smsCode(params).then(res => {
                    if (res.data.code && res.data.code === 200) {
                    this.$message.success('发送成功');
                }else{
                    var  msgstr=res.data.msg;
                    this.$message.error('发送失败:'+msgstr);
                }
            })
                this.timeCountdown(this.login) // 参数为最终对象
            },
            timeCountdown (obj) {
                // obj包括timer、waitTime 、canGet
                const TIME_COUNT = 60 // 默认倒计时秒数
                if (!obj.timer) {
                    obj.waitTime = TIME_COUNT
                    obj.canGet = false
                    obj.timer = setInterval(() => {
                        if (obj.waitTime > 0 && obj.waitTime <= TIME_COUNT) {
                        obj.waitTime--
                    } else {
                        obj.canGet = true
                        clearInterval(obj.timer) // 清空定时器
                        obj.timer = null
                    }
                }, 1000)
                }
                return {
                    timer: obj.timer,
                    canGet: obj.canGet,
                    waitTime: obj.waitTime
                }
            }
        }
    }
</script>
<style lang="less">

    // .labelstyle .el-form-item__label{
    //     font-size:18px;
    //     font-family: 'MicrosoftYaHei';
    //     color:#000000;
    //     margin-top:1vh;
    // }
    // .regformpwd .el-form-item__label{
    //     line-height:30px;
    // }
    // .regformpwd .el-input__inner {
    //     border-color: #42B134;
    //     border-radius : 7px;
    // }

    // .regformpwd.el-input__inner:focus {
    //     border-color: #42B134;
    //     border-radius: 7px;
    // }
    // .formstyle{
    //     margin-left:40%;
    //     font-family: "MicrosoftYaHei";
    //     color:#000000;
    // }
    // .mttop{
    //     margin-top:2vh;
    // }
    // .forgetpwdbtn{
    //     width:290px;
    //     height:40px;
    //     background-color: #42B134;
    //     border-radius: 7px;
    //     color:#FFFFFF;
    //     text-align: center;
    //     cursor:pointer;
    // }
    // .sendmsgcode{
    //     width: 120px;
    //     height: 40px;
    //     background: #42B134;
    //     border-radius: 7px;
    //     color:#FFFFFF;
    //     text-align: center;
    //     margin-left:1vw;
    //     padding:10px;
    //     padding-left:20px;
    //     padding-right:20px;
    //     cursor:pointer;
    // }
    // .checkcodes{
    //     width:16%;
    // }
    // .inputstyle{
    //     width: 30%;
    // }
    // .regformpwd{
    //     margin-top:4vh;
    // }
    // .ljlogin{
    //     color:#42B134;
    //     font-family: 'MicrosoftYaHei';
    //     cursor:pointer;
    // }
    // .yyaccount{
    //     margin-left:40vw;
    //     color:#000000;
    //     font-family: 'MicrosoftYaHei';
    //     margin-top:1vh;
    // }
    // .cleftmargin{
    //     margin-left:35vw;
    // }
    // .cjtitle{
    //     font-size:35px;
    //     color:#42B134;
    //     font-weight: bolder;
    //     padding-top:15vh;
    //     padding-left:5vw;
    // }
    // .container{
    //     background-image:url(../assets/img/bg.png);
    //     background-repeat: no-repeat;
    //     width:100%;
    //     min-height: 100vh;
    //     background-size: 100% 100%;

    // }
    // .logo{
    //     position: absolute;
    //     background-image:url(../assets/img/logo.png);
    //     background-repeat: no-repeat;
    //     width:185px;
    //     height:72px;
    //     margin-left:40vw;
    //     margin-top:20px;
    //     z-index:2000;

    // }
    // .box-card{

    // }
</style>