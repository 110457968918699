<template>
    <div class="commuter">
        <div class="hc">
            <div class="btnoper flex">
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotosfzshibie">身份证识别</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotogatxzshibie">护照港澳台识别</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotocompanyperson">本公司人员</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotopassenger">常用乘车人</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotowenbenshibie">文本识别</el-button>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in ruleForm.passengersList" :key="index">
            <div class="pginfo flex">
                <div class="xuhao">
                    {{ index + 1 }}
                </div>
                <div class="pginfotitle">乘客信息</div>
                <div class="pgdel" @click="remove(index)">【删除】</div>
            </div>
            <div class="hcpg">
                <el-form size="small" :inline="true" label-width="100px" class="formstyleyd" ref="ruleForm"
                    :model="ruleForm">
                    <el-form-item label="人员类型" class="mttop" prop="sendcity">
                        <el-col :span="24">
                            <el-select v-model="item.rytype" placeholder="选择人员类型">
                                <el-option label="成人" value="1"></el-option>
                                <el-option label="儿童" value="2"></el-option>
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="姓名" class="mttop fsizestyle" prop="sendcity">
                        <el-col :span="24">
                            <el-input class="inputstyle" placeholder="请输入" v-model="item.user" clearable></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="证件类型" class="mttop" prop="sendcity">
                        <el-col :span="24">
                            <el-select v-model="item.idtype" placeholder="选择证件类型">
                                <el-option label="身份证" value="1"></el-option>
                                <el-option label="护照" value="2"></el-option>
                            </el-select>
                        </el-col>
                    </el-form-item>
                </el-form>
                <el-form  :inline="true" ref="form" :model="form" label-width="100px">
                    <el-form-item label="席座" class="" prop="sendcity" v-if="type === '1'">
                        <el-col :span="24">
                            <el-select v-model="item.zuoxi" placeholder="选择席座">
                                <el-option v-for="item in xizuoList" :label="item.label" :key="item.value"
                                    :value="item.value"></el-option>
                                <!-- 1 无座 2 二等座 3 一等座 4 硬卧 5 软卧 6 硬座 7 商务座 -->
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="证件号码" class="fsizestyle" prop="id">
                        <el-col :span="24">
                            <el-input class="zjinputstyle" placeholder="请输入" v-model="item.id" clearable></el-input>
                        </el-col>
                    </el-form-item>
                </el-form>

                <el-form size="small" :inline="true" ref="form" :model="form" label-width="100px">
                    <el-form-item label="购买保险" class="fsizestyle" prop="isbaoxian">
                        <el-checkbox class="all_t" v-model="item.isbaoxian">需要</el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="addpg">
            <el-button class="pgbtn btnsinfo" @click="addPassenger">新增乘客</el-button>
        </div>
        <el-dialog title="常用乘车人" v-show="dialogcypgVisible" :visible="dialogcypgVisible" width="50%" top="80px"
            :before-close="handleClosecypg" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                    <el-row :gutter="24" v-if="showPersonSelect">
                        <el-col :span="8" v-for="item in companyList" :key="item.id">
                            <div class="pgdivinfo">
                                <div class="pgopertop flex">
                                    <div class="pgsinfo">
                                        <el-checkbox class="pgck"
                                            @change="(v) => handleCheckAllChangePassengerinfo(v, item)">{{ item.xingming
                                            }}</el-checkbox>
                                    </div>
                                    <div class="pgkuang">
                                        {{ item.personType === "1" ? "成人" : "儿童" }}
                                    </div>
                                    <div class="sfz">身份证</div>
                                    <div class="editicon">
                                        <el-image class="editimgs" :src="require('../assets/img/editimg.png')"
                                            fit="cover"></el-image>
                                    </div>
                                </div>
                                <div class="pgcardid">{{ item.idNumber }}</div>
                            </div>
                        </el-col>
                        <el-empty v-if="companyList.length == 0" description="无人员"></el-empty>
                    </el-row>

                    <div class="pgbtns">
                        <el-button class="login-btn confirmbtn" type="primary" @click="confirmPerson"
                            style="background-color: rgba(66, 177, 52, 1)" :loading="logLoading">确认</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>

        <el-dialog title="本公司人员" v-show="dialogbgsryVisible" :visible="dialogbgsryVisible" width="50%" top="80px"
            :before-close="handleClosebgsry" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                    <el-row :gutter="24" v-if="showPersonSelect">
                        <el-col :span="8" v-for="item in companyList" :key="item.id">
                            <div class="pgdivinfo">
                                <div class="pgopertop flex">
                                    <div class="pgsinfo">
                                        <el-checkbox class="pgck"
                                            @change="(v) => handleCheckAllChangePassengerinfo(v, item)">{{ item.xingming
                                            }}</el-checkbox>
                                    </div>
                                    <div class="pgkuang">
                                        {{ item.personType === "1" ? "成人" : "儿童" }}
                                    </div>
                                    <div class="sfz">身份证</div>
                                    <div class="editicon">
                                        <el-image class="editimgs" :src="require('../assets/img/editimg.png')"
                                            fit="cover"></el-image>
                                    </div>
                                </div>
                                <div class="pgcardid">{{ item.idNumber }}</div>
                            </div>
                        </el-col>

                        <el-empty v-if="companyList.length == 0" description="无人员"></el-empty>
                    </el-row>
                    <div class="pgbtns">
                        <el-button class="login-btn confirmbtn" type="primary" @click="confirmPerson"
                            style="background-color: rgba(66, 177, 52, 1)" :loading="logLoading">确认</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="身份证识别" v-show="dialogsfzVisible" :visible="dialogsfzVisible" width="30%" top="80px"
            :before-close="handleCloseLoginsfz" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                    <el-form-item>
                        <span slot="label">
                            <span class="spanstyle">上传身份证正面</span>
                        </span>
                        <el-upload :show-file-list="false" :fileList="fileList" :on-success="handleSuccess"
                            action="/api/api/train/orderinfo/cardidshibie" list-type="picture-card" :limit="1">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item class="confirmbtns">
                        <el-button class="login-btn cbtns" type="primary" style="background-color: rgba(66, 177, 52, 1)"
                            :loading="logLoading">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="护照港澳通行证识别" v-show="dialoggatxzVisible" :visible="dialoggatxzVisible" width="35%" top="80px"
            :before-close="handleCloseLogingatxz" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="200px">
                    <el-form-item>
                        <span slot="label">
                            <span class="spanstyle">上传护照港澳通行证正面</span>
                        </span>
                        <el-upload :show-file-list="false" :fileList="fileList" :on-success="handleSuccessGat"
                            action="/api/api/train/orderinfo/gethuzhaoshibie" list-type="picture-card" :limit="1">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item class="confirmbtns">
                        <el-button class="login-btn cbtns" type="primary" style="background-color: rgba(66, 177, 52, 1)"
                            :loading="logLoading">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="文本识别" v-show="dialogVisible" :visible="dialogVisible" width="600px" top="80px"
            :before-close="handleCloseLogin" class="tpinfo">
            <el-card class="box-card">
                <el-input type="textarea" ref="textareaRef" class="wenzi-textarea" :rows="6"
                    v-model="textareaValue"></el-input>
                <div class="btn-row">
                    <el-button class="login-btn cbtns" :loading="logLoading" @click="handleCloseLogin">取消</el-button>
                    <el-button class="login-btn cbtns" type="primary" style="background-color: rgba(66, 177, 52, 1)"
                        :loading="logLoading" @click="handleSuccessWenzi">快速添加</el-button>
                </div>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import ElCard from "element-ui/packages/card/src/main";
import ElButton from "element-ui/packages/button/src/button";
import ElCheckbox from "element-ui/packages/checkbox/src/checkbox";
import Cookie from "js-cookie";
import { queryPassengerlist } from "@/api";
export default {
    name: "CommuterVue",
    components: {
        ElButton,
        ElCard,
        ElCheckbox,
    },
    props: {
        type: {
            type: String,
            default: "1" // 1 火车票  2  飞机票
        }
    },
    activated() {
        this.ruleForm.passengersList = [
            {
                rytype: "",
                user: "",
                idtype: "",
                id: "",
                zuoxi: "",
                isbaoxian: "",
            }
        ],
            this.seat = this.$route.query.seat ? this.$route.query.seat.split('-') : [];
        this.handleCloseLogin()
        this.handleCloseLogingatxz()
        this.handleCloseLoginsfz()
        this.handleClosebgsry()
        this.handleClosecypg()
    },
    computed: {
        xizuoList() {
            //  <!-- 1 无座 2 二等座 3 一等座 4 硬卧 5 软卧 6 硬座 7 商务座 -->
            return [
                { label: '无座', value: '1' },
                { label: '二等座', value: '2' },
                { label: '一等座', value: '3' },
                { label: '硬卧', value: '4' },
                { label: '软卧', value: '5' },
                { label: '硬座', value: '6' },
                { label: '商务座', value: '7' },
            ].filter(item => this.seat.includes(item.label))
        }
    },
    data() {
        return {
            pgname: "",
            dialogbgsryVisible: false,
            dialogcypgVisible: false,
            dialoggatxzVisible: false,
            dialogsfzVisible: false,
            logLoading: false,
            dialogVisible: false,
            textareaValue: "",
            form: {
                name: "",
                region: "",
                date1: "",
                date2: "",
                delivery: false,
                type: [],
                resource: "",
                desc: "",
            },
            ruleForm: {
                passengersList: [
                    {
                        rytype: "",
                        user: "",
                        idtype: "",
                        id: "",
                        zuoxi: "",
                        isbaoxian: "",
                    },
                ],
            },
            rules: {
                mobilephone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
                ],

                pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            fileList: [],
            companyList: [],
            defaultUserList: [],
            checkList: [],
            showPersonSelect: false,
            seat: []
        };
    },
    created() {
        this.getCompanyUserList();
    },
    methods: {
        getCompanyUserList() {
            const user = Cookie.get("phoneKey");
            if (user) {
                queryPassengerlist({ mobilePhone: user }).then(({ data, status }) => {
                    // console.log("%c Line:547 🍰 status", "color:#7f2b82", status);
                    if (status === 200) {
                        this.companyList = data.data.ret;
                    }
                    console.log("%c Line:541 🥃 rs", "color:#4fff4B", data);
                });
            }
        },
        //本公司人员
        gotocompanyperson() {
            this.dialogbgsryVisible = true;
            this.showPersonSelect = true;
        },
        handleClosebgsry() {
            this.dialogbgsryVisible = false;
            this.showPersonSelect = false;
        },
        //选择
        handleCheckAllChangePassengerinfo(...args) {
            if (args[0]) {
                this.checkList.push(args[1]);
            } else {
                this.checkList.splice(this.checkList.indexOf(args[1]), 1);
            }
        },
        confirmPerson() {
            this.checkList.map((item) => {
                const data = {
                    rytype: item.personType,
                    user: item.xingming,
                    idtype: item.idType,
                    id: item.idNumber,
                    zuoxi: "",
                    isbaoxian: "",
                };
                this.addData(data);
            })
            this.handleClosecypg();
            this.handleClosebgsry();
            this.checkList = [];
        },
        //关闭常用乘车人
        handleClosecypg() {
            this.showPersonSelect = false;
            this.dialogcypgVisible = false;
            // this.$refs.form.resetFields();
            // this.$store.commit("collapseFuntchose");
        },
        //常用乘车人弹窗
        gotopassenger() {
            this.dialogcypgVisible = true;
            this.showPersonSelect = true;
        },
        //护照港澳通行证关闭弹窗
        handleCloseLogingatxz() {
            this.dialoggatxzVisible = false;
            // this.$refs.form.resetFields();
            this.$store.commit("collapseFuntchose");
        },
        //护照港澳通行证识别
        gotogatxzshibie() {
            this.dialoggatxzVisible = true;
        },
        //身份证识别
        gotosfzshibie() {
            this.dialogsfzVisible = true;
        },
        //身份证识别关闭
        handleCloseLoginsfz() {
            this.dialogsfzVisible = false;
            // this.$refs.form.resetFields();
            this.$store.commit("collapseFuntchose");
        },
        //清除方法
        gotoclearBtn() {
            this.pgshow = false;
        },
        //删除乘客
        remove(idx) {
            if (
                this.ruleForm.passengersList.length === 1
            ) {
                this.$message.warning("至少要有一位乘客信息！");
                return;
            } else {
                this.ruleForm.passengersList.splice(idx, 1);
            }
        },
        //新增乘客
        addPassenger() {
            // if (this.ruleForm.passengersList.length >= 9) {
            //     this.$message.warning('最多9位乘机人！');
            //     return
            // } else {
            const obj = {
                rytype: "",
                user: "",
                idtype: "",
                id: "",
                zuoxi: "",
                isbaoxian: "",
            };
            this.ruleForm.passengersList.push(obj);
            // }
        },
        //文本识别
        gotowenbenshibie() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.textareaRef.focus();
            })
        },

        handleCloseLogin() {
            this.dialogVisible = false;
            this.textareaValue = ""
        },
        //转向预定申请
        forwardyuding() {
            this.$router.push("/trainyudingshenqing");
        },
        getAgeFromIdCard(idCard) {
            if (!idCard || idCard.length !== 18) {
                throw new Error('Invalid ID card number');
            }

            const today = new Date();
            const birthDateString = idCard.substring(6, 14);
            const birthDate = new Date(
                birthDateString.substring(0, 4), // year
                parseInt(birthDateString.substring(4, 6)) - 1, // month (zero-based)
                birthDateString.substring(6, 8) // day
            );

            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        },
        addData(data) {
            const emptyIndex = this.ruleForm.passengersList.findIndex(item => {
                return Object.keys(item).every(key => !item[key])
            });

            if (emptyIndex !== -1) {
                this.ruleForm.passengersList.splice(emptyIndex, 1, data);
            }
            else {
                this.ruleForm.passengersList.push(data)
            }
        },
        handleSuccess(res, file) {
            const { data } = res;
            try {
                const result = JSON.parse(data.body);
                const { words_result } = result;
                const _data = {
                    user: words_result['姓名'] ? words_result['姓名'].words : '',
                    idtype: '1',
                    id: words_result['公民身份号码'] ? words_result['公民身份号码'].words : '',
                    rytype: '1',
                    zuoxi: "",
                    isbaoxian: false,
                }
                this.addData(_data)
                this.handleCloseLoginsfz()
                this.$message.success('识别成功')
            } catch (error) {
                console.log("%c Line:409 🍯", "color:#465975", error.message);
            }
            this.fileList = [];
        },
        handleSuccessGat(res, file) {
            const { data } = res;
            try {
                const result = JSON.parse(data.body);
                const { words_result } = result;
                console.log("%c Line:491 🌮 words_result", "color:#7f2b82", words_result);
                const _data = {
                    user: words_result['姓名拼音'] ? words_result['姓名拼音'][0].word : '',
                    idtype: '2',
                    id: words_result['护照号'] ? words_result['护照号'][0].word : '',
                    rytype: '1',
                    zuoxi: "",
                    isbaoxian: false,
                }

                console.log("%c Line:501 🍉", "color:#f5ce50", _data);
                this.addData(_data)
                this.handleCloseLogingatxz()
                this.$message.success('识别成功')
            } catch (error) {
                console.log("%c Line:409 🍯", "color:#465975", error.message);
            }
            this.fileList = [];
        },
        validateSfz(value) {
            if (!value || value.length !== 18) {
                // this.$message.error('请输入正确的身份证号');
                return false
            }
            return true
        },
        handleSuccessWenzi() {
            if (!this.textareaValue) return;
            try {
                const lines = this.textareaValue.split('\n').filter(line => line.trim() !== '');
                const result = lines.map(line => {
                    const [name, id] = line.split(/[\s，,~\-]+/).filter(item => item.trim());
                    return { name, id };
                });

                result.forEach(item => {
                    if (item.id && this.validateSfz(item.id)) {
                        this.addData({
                            user: item.name,
                            idtype: '1',
                            id: item.id,
                            rytype: '1',
                            zuoxi: "",
                            isbaoxian: false,
                        })
                    }
                    else {
                        throw new Error('请输入文本')
                    }
                })

                console.log("%c Line:548 🍇", "color:#33a5ff", result);
            } catch (error) {
                this.$message.error(error.message);
            }

            this.handleCloseLogin()
            this.textareaValue = ''
            // console.log("%c Line:527 🍎", "color:#fca650", result);
            // console.log("%c Line:507 🥕", "color:#2eafb0", this.textareaValue);
        },
    },
};
</script>

<style scoped>
.mttop{margin-top: 2vh;}
.flex {
    display: flex;
}

/* .addpg {
  margin-left: 20vw;
  margin-right: 20vw;
} */

.xuhao {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
}

.sfsbbtn {
    border: 1px solid #000000;
    color: #000000;
    margin-left: 1vw;
}

.hc {
    background-color: #f1f1f1;
    height: 100px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    align-items: center;
    display: flex;
}

.hcpg {
    background-color: #f1f1f1;
    height: 200px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.formstyleyd .inputstyle {
    width: 192px;
}

.zjinputstyle {
    width: 192px;
}

.pgdivinfo {
    height: 93px;
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 20px;
}

.spanstyle {
    margin-top: 50px;
    display: inline-block;
}

.addpg {
    text-align: center;
    width: calc(88% - 0px - 10px) !important;
}

.pginfotitle {
    width: calc(100% - 0px - 70px) !important;
    font-weight: bolder;
    margin-left: 1vw;
}

.pginfo {
    margin-top: 2vh;
}

.pgdel {
    width: 70px;
}

.tpinfo /deep/ .el-dialog {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.tpinfo /deep/ .el-card {
    border: 1px solid #ffffff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: none;
}

.tpinfo /deep/ .el-card__body {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.tpinfo /deep/ .el-dialog__body {
    padding: 0;
    color: #606266;
    font-size: 14px;
    /*word-break: break-all;*/
}

.pgbtn {
    width: 350px;
    height: 45px;
    border: 1px solid #000000;
    color: #000000;
    padding: 10px;
    margin-top: 5vh;
}

.btnsinfo {
    background-color: rgba(255, 255, 255, 1);
}

.pgcardid {
    color: #999999;
    margin-left: 34px;
}

.editimgs {
    width: 19px;
    height: 17px;
    margin-left: 10px;
}

.pgopertop {
    align-items: center;
    height: 60px;
}

.pgkuang {
    width: 47px;
    height: 22px;
    line-height: 22px;
    border: 1px solid #b6b6b6;
    text-align: center;
    margin-left: 20px;
    color: #999999;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    vertical-align: middle;
}

.sfz {
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    margin-left: 10px;
}

.pgck {
    color: #000000;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 18px;

    padding-left: 10px;
}

.pgleftinfo {
    margin-left: 25px;
}

.pgmleft {}

.pgmargintop {
    margin-top: 20px;
}

.btnstylesinfo {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    text-align: center;
}

.cbtns {
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    /* margin-left: -25px; */
}

.confirmbtns {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    justify-content: center;
}

.confirmbtn {
    width: calc(48% - 0px - 10px) !important;
    padding-left: 100px;
    padding-right: 100px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.pgbtns {
    margin-top: 20px;
    width: calc(100% - 0px - 0px) !important;
    justify-content: center;
    text-align: center;
}

.pgtop {
    margin-top: 10px;
}

.pgleft {
    margin-left: 10px;
}

.centered-label .el-form-item__label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clearbtn {
    background-color: rgba(150, 150, 150, 1);
    color: #ffffff;
}

.wenzi-textarea /deep/ .el-textarea__inner {
    border: none;
    outline: none;
}

.webit-textarea /deep/ .el-textarea__inner:focus {
    border: none;
    outline: none;
}

.btn-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
</style>
