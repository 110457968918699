<template>
    <div>

        <el-container>
            <el-header height="auto"><common-header /></el-header>
            <el-container>
                <el-aside class="no-top" width="auto">
                    <common-asideVue />
                </el-aside>
                <el-main class="el-main">
                    <trainorderdetails-right />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import ElContainer from "element-ui/packages/container/src/main";
    import CommonAsideVue from '@/components/CommonAside.vue'
    import CommonHeader from '@/components/CommonHeader.vue'
    import TrainyudingRight from "../components/TrainyudingRight";
    import TrainyudingshenqingRight from "../components/TrainyudingshenqingRight";
    import TrainorderdetailsRight from "../components/TrainorderdetailsRight";
    export default {
        components: {
            TrainorderdetailsRight,
            ElContainer,CommonHeader,CommonAsideVue},
        name: "trainorderdetails",
        data () {
            return {}
        },
        methods: {

        }
    }
</script>

<style scoped>
    .el-header{
        padding:0;
    }
</style>