<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')"
                          fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>个人中心</span>
            </div>

        </div>
        <div class="hc flex">
            <div class="hcleft">Hi~ 15266666666</div>
            <div class="flex" @click="gotoupdateziliao">
                <div class="updatezl">去修改基本资料</div>
                <div class="zlicon">
                    <el-image class="weiziicon" :src="require('../assets/img/ziliao.png')"
                              fit="cover"></el-image>
                </div>
            </div>
        </div>
        <el-tabs  class="tabwidth" v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane  label="站内消息"  name="first">
                <div class="tcontent flex">
                    <div class="scheci ">
                        中安合作是好朋友之间的相互帮助 互惠互利互中安合作是好朋友之间的相
                    </div>

                    <div class="checimoney " style="color:#999999;">
                        发布时间：2024-03-03
                    </div>

                    <div class="operbtn">
                        未读
                    </div>
                </div>
                <div class="tcontent flex">
                    <div class="scheci ">
                        中安合作是好朋友之间的相互帮助 互惠互利互中安合作是好朋友之间的相
                    </div>

                    <div class="checimoney " style="color:#999999;">
                        发布时间：2024-03-03
                    </div>

                    <div class="operbtn">

                    </div>
                </div>
                <div class="pager flex">
                    <div class="shouye">
                        <el-button class="sybtn">首页</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno defaultbtn">1</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">2</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">3</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">4</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">5</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">6</el-button>
                    </div>
                    <div class="shouye mleftmargin">
                        <el-button class="sybtn">末页</el-button>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane class="cltab" label="修改密码"   name="updatepwd">
                <div class="tcontentpwd">
                    <el-form
                            size="small"
                            :inline="true"
                            ref="form"
                            :model="form"
                            label-width="100px"
                            class="formstylegrzx"

                    >


                        <el-form-item label=""  class=" fsizestyle" prop="mobilephone">

                            <span class="spanstyles">
                                手机号码：
                            </span>
                            <el-input class="inputstyle heightstyle"  placeholder="请输入" v-model="form.mobilephone" clearable></el-input>

                        </el-form-item>
                        <el-form-item label=""  class=" fsizestyle" prop="checkcode">
                            <span class="spanstyles">
                                验证码：
                            </span>
                            <el-input class="inputstyle heightstyle"  placeholder="请输入" v-model="form.checkcode" clearable></el-input>

                        </el-form-item>
                        <el-form-item >
                            <el-col :span="25">
                                <el-button class="sendcodebtn">发送验证码</el-button>
                            </el-col>
                        </el-form-item>



                    </el-form>

                    <el-form
                            size="small"
                            :inline="true"
                            ref="form"
                            :model="form"
                            label-width="80px"
                            class="formstylegrzx"
                    >
                        <el-form-item label=""  class=" fsizestyle" prop="newspwd">
                              <span class="spanstyles">
                                新密码：
                             </span>
                            <el-input show-password class="inputstyle heightstyle"  placeholder="请输入" v-model="form.newspwd" clearable></el-input>

                        </el-form-item>
                        <el-form-item label=""  class=" fsizestyle" prop="confirmpwd">
                                  <span class="spanstyles">
                                     确认密码：
                                  </span>
                            <el-input show-password class="inputstyle heightstyle"  placeholder="请输入" v-model="form.confirmpwd" clearable></el-input>

                        </el-form-item>


                    </el-form>
                </div>

                <div class="savebtndiv" >
                    <el-button class="savebtn">保存</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane class="cltab" label="解绑手机"   name="jiebangphone">
                <div class="tcontentpwd flex">
                    <el-form
                            size="small"
                            :inline="true"
                            ref="form"
                            :model="formjbp"
                            label-width="100px"
                            class="formstylegrzx"
                    >


                        <el-form-item label="手机号码" class=" fsizestyle" prop="mobilephone">
                            <el-col :span="20">
                                <el-input class="inputstyle heightstyle"  placeholder="请输入" v-model="formjbp.mobilephone" clearable></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="验证码" class=" fsizestyle" prop="checkcode">
                            <el-col :span="20">
                                <el-input class="inputstyle heightstyle"  placeholder="请输入" v-model="formjbp.checkcode" clearable></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item >
                            <el-col :span="25">
                                <el-button class="sendcodebtn">发送验证码</el-button>
                            </el-col>
                        </el-form-item>


                    </el-form>

                </div>
                <div class="savebtndiv">
                    <el-button class="savebtn">保存</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane  class="cltabjsd" label="人员管理" name="emp">
                <div class="operatbtn flex">
                    <div class="addbtn">
                        <el-form
                                size="small"
                                :inline="true"
                                ref="form"
                                :model="formry"
                                label-width="100px"
                                class="formstylegrzx"
                        >

                            <el-form-item >
                                <el-col :span="20">
                                    <el-button class="addbtn" style="background-color: #f1f1f1;color:#000000;font-size:14px;">+新增</el-button>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="shenhebtn clleft">
                        <el-form
                                size="small"
                                :inline="true"
                                ref="form"
                                :model="form"
                                label-width="100px"
                                class="formstylegrzx"
                        >
                            <el-form-item>
                                <el-col :span="20">
                                    <el-button class="addbtn" @click="gotodaishenhe" style="background-color: #f1f1f1;color:#000000;font-size:14px;">待审核</el-button>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="clleft">
                        <el-form
                                size="small"
                                :inline="true"
                                ref="form"
                                :model="form"
                                label-width="80px"
                                class="formstylegrzx"
                        >
                            <el-form-item  class="mtinfo"  prop="deptname">
                                <el-col :span="20">
                                    <el-select v-model="formry.deptname" placeholder="选择部门">
                                        <el-option label="软件研发部" value="0"></el-option>
                                        <el-option label="测试部门" value="1"></el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                            <el-form-item    class="mtinfo" prop="inputname">
                                <el-col :span="20">
                                    <el-input class="inputstyle heightstyle"  placeholder="输入名称搜索"  v-model="formry.inputname" clearable></el-input>
                                </el-col>
                            </el-form-item>
                            <el-form-item   class="mtinfo" prop="sendcity">
                                <el-col :span="20">
                                    <el-button class="querybtn searchbtn" style="margin-left:20px;">搜索</el-button>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
                <div class="cpgcontent flex">
                    <div class="pgcontent">
                        <div class="pcdiv flex">
                            <div>部门：</div>
                            <div>某某部门</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>姓名：</div>
                            <div>韩某某</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>手机号码：</div>
                            <div>15536987422</div>
                            <div class="updatebtn">修改</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>性别：</div>
                            <div>男</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>人员类型：</div>
                            <div>成人</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>证件类型：</div>
                            <div>身份证</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>证件号码：</div>
                            <div>11000XXXXXXXXXXXX</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>证件照片：</div>
                            <div class="imgzhengmian">
                                <el-image class="weiziicon" :src="require('../assets/img/shenfenzheng012x.png')"
                                          fit="cover"></el-image>
                            </div>
                            <div class="imgfanmian">
                                <el-image class="weiziicon" :src="require('../assets/img/shenfenzheng022x.png')"
                                          fit="cover"></el-image>
                            </div>
                        </div>
                        <div class="pcdiv ">
                            <div class="delbtn">删除</div>

                        </div>

                    </div>

                    <div class="pgcontent mleftpg">
                        <div class="pcdiv flex">
                            <div>部门：</div>
                            <div>某某部门</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>姓名：</div>
                            <div>韩某某</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>手机号码：</div>
                            <div>15536987422</div>
                            <div class="updatebtn">修改</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>性别：</div>
                            <div>男</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>人员类型：</div>
                            <div>成人</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>证件类型：</div>
                            <div>身份证</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>证件号码：</div>
                            <div>11000XXXXXXXXXXXX</div>
                        </div>
                        <div class="pcdiv flex">
                            <div>证件照片：</div>
                            <div class="imgzhengmian">
                                <el-image class="weiziicon" :src="require('../assets/img/shenfenzheng012x.png')"
                                          fit="cover"></el-image>
                            </div>
                            <div class="imgfanmian">
                                <el-image class="weiziicon" :src="require('../assets/img/shenfenzheng022x.png')"
                                          fit="cover"></el-image>
                            </div>
                        </div>
                        <div class="pcdiv ">
                            <div class="delbtn">删除</div>

                        </div>

                    </div>
                </div>


                <div class="pager flex">
                    <div class="shouye">
                        <el-button class="sybtn">首页</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno defaultbtn">1</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">2</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">3</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">4</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">5</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">6</el-button>
                    </div>
                    <div class="shouye mleftmargin">
                        <el-button class="sybtn">末页</el-button>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane class="cltab" label="部门管理"  name="dept">
                <div class="deptdiv" @click="adddept">
                    <div class="addbtns">+新增</div>
                </div>
                <div>
                    <el-table :data="tableData" stripe style="width:100%;">
                        <el-table-column prop="dateinfo" label="名称" width="600%"  align="center">

                        </el-table-column>
                        <el-table-column prop="orderleixing" label="排序值" width="200%"  align="center">

                        </el-table-column>
                        <el-table-column prop="payleixing" label="人数" width="200%"  align="center">

                        </el-table-column>

                        <el-table-column prop="mingxi" label="操作" width="200%" align="center">
                            <button class="btn-clz1">修改</button>
                            <button class="btn-clzgray">删除</button>
                        </el-table-column>

                    </el-table>
                </div>
                <div class="pager flex">
                    <div class="shouye">
                        <el-button class="sybtn">首页</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno defaultbtn">1</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">2</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">3</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">4</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">5</el-button>
                    </div>
                    <div class="btnnodiv">
                        <el-button class="btnno">6</el-button>
                    </div>
                    <div class="shouye mleftmargin">
                        <el-button class="sybtn">末页</el-button>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane class="cltabjsd" label="结算单"  name="jiesuandan">
                <div class="tcontentdt flex">
                    <div class="jiesuandantitle">
                        结算单详情
                    </div>
                </div>

                <div class="tcontentdttwo">
                    <div class="tcone flex">
                        <div class="tcleftfirst">ID:10021000</div>
                        <div class="tcleftc">名称:2024年某某公司</div>
                        <div class="tcleftc">结算单金额:￥10000.00</div>
                        <div class="tcleftc">创建人:张三</div>
                        <div class="tcleft">创建时间:2024-12-20 10:10:10</div>
                    </div>

                    <div class="hengxians"></div>
                    <div class="tctwo flex">
                        <div class="tctwoleftfirst">流水号：100000</div>
                        <div class="tctwoleft">日期：2024-02-02 </div>
                        <div class="tctwoleft">出发：北京大兴  </div>
                        <div class="tctwoleft">到达：上海浦东 </div>
                        <div class="tctwoleft">张数：20 </div>
                        <div class="tctwoleft">金额信息:￥10000.00</div>
                    </div>
                    <div class="tctwo flex">
                        <div class="tctwoleftfirst">流水号：100000</div>
                        <div class="tctwoleft">日期：2024-02-02 </div>
                        <div class="tctwoleft">出发：北京大兴  </div>
                        <div class="tctwoleft">到达：上海浦东 </div>
                        <div class="tctwoleft">张数：20 </div>
                        <div class="tctwoleft">金额信息:￥10000.00</div>
                    </div>
                    <div class="tctwo flex">
                        <div class="tctwoleftfirst">流水号：100000</div>
                        <div class="tctwoleft">日期：2024-02-02 </div>
                        <div class="tctwoleft">出发：北京大兴  </div>
                        <div class="tctwoleft">到达：上海浦东 </div>
                        <div class="tctwoleft">张数：20 </div>
                        <div class="tctwoleft">金额信息:￥10000.00</div>
                    </div>

                </div>
                <div class="remark">
                    结算说明：
                </div>
                <div class="remarkinfo">
                    <el-input type="textarea" v-model="textareaValue"
                              show-word-limit resize="none" :rows="5"
                              class="textarea-box" placeholder="这里显示文字的说明，可多行"></el-input>
                </div>


            </el-tab-pane>
        </el-tabs>


        <el-dialog
                title="审核"
                v-show="dialogVisible || isCollapse"
                :visible="dialogVisible || isCollapse"
                width="500px"
                top="80px"
                :before-close="handleCloseLogin"
        >
            <el-card shadow="never" class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                    <div>
                        <div class="remarkinfo">
                            <el-input type="textarea" v-model="textareaValue"
                                      show-word-limit resize="none" :rows="5"
                                      class="textarea-box" placeholder="这里显示审核意见，通过时选填，拒绝时必填"></el-input>
                        </div>
                    </div>
                    <div class="btnstylesinfo">
                        <el-button
                                class="login-btn clearbtn" @click="gotoclearBtn">
                            拒绝
                        </el-button>
                        <el-button
                                class="login-btn"
                                type="primary"
                                @click="handleLogin"
                                style="background-color: rgba(66, 177, 52, 1);"
                                :loading="logLoading"
                        >通过</el-button
                        >
                    </div>
                </el-form>
            </el-card>
        </el-dialog>


        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
    </div>
</template>

<script>
    import ElImage from "element-ui/packages/image/src/main";
    import ElCard from "element-ui/packages/card/src/main";
    import ElButton from "element-ui/packages/button/src/button";
    export default {
        components: {
            ElButton,
            ElCard,
            ElImage
        },
        data () {
            return {
                dialogVisible:false,
                tableDatajiesunadan:[{
                    dateinfo:'2020-02-02 12:00',
                    liushuino:'100000，100000，100000，100000，100000，100000，100000，100000，1',
                    orderleixing:'机票',
                    payleixing:'订单支付',
                    mingxi:'张三',
                    createdates:'2023-04-01 12:20:30'

                },{
                    dateinfo:'2020-02-02 12:00',
                    liushuino:'100000，100000，100000，100000，100000，100000，100000，100000，1',
                    orderleixing:'机票',
                    payleixing:'订单支付',
                    mingxi:'张三',
                    createdates:'2023-04-01 12:20:30'

                }],
                tableData:[{
                    dateinfo:'某某一部门',
                    orderleixing:'1',
                    payleixing:'20',


                },{
                    dateinfo:'某某一部门',
                    orderleixing:'1',
                    payleixing:'20',
                },{
                    dateinfo:'某某一部门',
                    orderleixing:'1',
                    payleixing:'20',
                },{
                    dateinfo:'某某一部门',
                    orderleixing:'1',
                    payleixing:'20',

                },{
                    dateinfo:'某某一部门',
                    orderleixing:'1',
                    payleixing:'20',

                }],
                activeName:'jiesuandan',
                form: {
                    date1: "",
                    sendcity: "",
                    arrivalcity:"",
                    mobilephone:"",
                    checkcode:"",
                    newspwd:"",
                    confirmpwd:"",
                },
                formjbp:{
                    mobilephone:"",
                    checkcode:"",
                },
                formry:{
                    deptname:"",
                    inputname:"",
                },
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],

                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],

                }
            }
        },

        name: "jiesuandan-right",
        methods: {
            //转向新增部门
            adddept(){
                this.$router.push('/adddept');
            },
            //转向结算单详情
            gotojiesuandandetails(){
                //this.$router.push('/jiesuandan');
            },
            //待审核弹窗
            gotodaishenhe(){
                //this.dialogVisible=true;
                this.$router.push('/renyuandaishenhe');
            },
            handleCloseLogin () {
                this.dialogVisible = false;
                this.$refs.form.resetFields();
                this.$store.commit('collapseFuntchose');
            },
            //转向去修改基本资料
            gotoupdateziliao(){
                this.$router.push('/updateziliao');
            }
        }
    }
</script>

<style scoped>
    .tpinfo /deep/ .el-dialog{
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }
    .tpinfo /deep/ .el-card{
        border:1px solid #FFFFFF;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }
    .tpinfo /deep/ .el-card__body{
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }
    .tpinfo /deep/ .el-dialog__body {
        padding:0;
        color: #606266;
        font-size: 14px;
        /*word-break: break-all;*/
    }
    .tctwo{
        padding:20px 20px 0 20px;;
        justify-content: space-between;
        align-items: center;
    }
    .tctwoleftfirst{

        margin-left:20px;
    }
    .tctwoleft{
        margin-left:20px;
    }
    .tcone{
        /* padding-top:30px; */
        padding:20px 20px 0 20px;;
        justify-content: space-between;
        align-items: center;
    }
    .tcleftfirst{
        /*width:150px;*/
        margin-left:20px;
    }
    .tcleftc{
        margin-left:20px;

    }
    .tcleft{
        /*width:400px;*/
        margin-left:40px;

    }
    .hengxians{
        width: calc(100% - 0px - 0px) !important;
        font-size:1px;
        border:1px solid rgba(210, 210, 210, 1);
        margin-top:20px;
    }
    .rytextarea-box{
        width: calc(100% - 0px - 0px) !important;

        margin-top:2vh;

    }
    .rytextarea-box  /deep/ .el-textarea__inner {
        border:1px solid #000000;
    }

    .textarea-box{
        width: calc(100% - 0px - 0px) !important;
        background-color: #F1F1F1;
        margin-top:2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .textarea-box  /deep/ .el-textarea__inner {
        background-color: #F1F1F1;
    }
    .remark{
        margin-top:20px;
    }
    .remarkinfo{
        width: calc(100% - 0px - 10px) !important;
    }
    .jiesuandantitle{
        padding-left:20px;
    }
    .login-btn{
        padding-left:40px;
        padding-right: 40px;
    }
    .clearbtn{
        background-color: rgba(181, 181, 181, 1);
        color:#FFFFFF;
    }
    .btnstylesinfo{
        margin-top:20px;
        text-align: center;
    }
    .tbtable{
        margin-right:10px;
        width: calc(98% - 0px - 10px) !important;
    }
    .btn-clzgray{
        padding-top: 5px;
        border-bottom-left-radius: 10px;
        color: #FFFFFF;
        padding-left: 10px;
        font-size: 13px !important;
        padding-bottom: 5px;
        border-top-right-radius: 10px;
        margin-right: 10px;
        background-color: rgba(137, 137, 137, 1);
        margin-left: 10px;
        overflow: hidden;
        width: calc(50% - 10px - 10px) !important;
        border-top-left-radius: 10px;
        margin-top: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 10px;
        text-align: center;
        padding-right: 10px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
    }
    .btn-clz1{
        padding-top: 5px;
        border-bottom-left-radius: 10px;
        color: #FFFFFF;
        padding-left: 10px;
        font-size: 13px !important;
        padding-bottom: 5px;
        border-top-right-radius: 10px;
        margin-right: 10px;
        background-color: #000000;
        margin-left: 10px;
        overflow: hidden;
        width: calc(50% - 10px - 10px) !important;
        border-top-left-radius: 10px;
        margin-top: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 10px;
        text-align: center;
        padding-right: 10px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
    }
    .mleftpg{
        margin-left:10px;

    }
    .mtinfo{
        margin-top:5px;
    }
    .addbtn{
        width:150px;
        height:45px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .defaultbtn{
        background-color: #42B134;
        color:#FFFFFF;
    }
    .weiziiconst{
        width:24px;
        height:24px;
    }
    .inputstyle{
        width:10vw;
    }
    .cdiv{

    }
    .deptdiv{
        width: calc(88% - 0px - 10px) !important;
        background-color: #F1F1F1;
        text-align: center;
        border-radius: 20px;
    }
    .addbtns{

        padding:10px;
        border-radius: 20px;

    }
    .mleftmargin{
        margin-left:1vw;
    }
    .pager{
        margin-top:5vh;

        width: calc(88% - 0px - 10px) !important;
        display: flex;
        justify-content: center;
    }
    .shouye{

    }
    .sybtn{
        width:100px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .btnnodiv{
        margin-left:1vw;
    }
    .btnno{
        width:50px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }

    .cpgcontent{
        margin-top:1vh;
    }
    .delbtn{
        border:1px solid #000000;
        border-radius: 10px;
        padding:5px;
        padding-left:10px;
        padding-right:10px;
        width:5vw;
        text-align: center;
        margin-left:20vw;
        margin-top:1vh;
    }

    .imgzhengmian{

    }
    .imgfanmian{
        margin-left:1vw;
    }
    .updatebtn{
        border:1px solid #000000;
        border-radius: 10px;
        padding:5px;
        margin-left:1vw;
        padding-left:10px;
        padding-right:10px;
    }
    .pgcontent{
        background-color: #F1F1F1;
        width:30vw;
        padding-left:2vw;
        padding-top:1vh;
        padding-bottom:2vh;
        font-size:13px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

    }
    .pcdiv{
        padding-top:1vh;
    }

    .clleft{
        margin-left:1vw;
    }
    .operatbtn{

    }
    .addbtn{

    }
    .savebtndiv{
        width: calc(88% - 0px - 10px) !important;
        margin-top:20px;
        text-align: center;


    }
    .savebtn{
        background-color:#42B134;
        color:#FFFFFF;
        width:400px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .sendcodebtn{
        background-color:#42B134;
        color:#FFFFFF;
        padding-left:10px;
        padding-right:10px;
        padding-top:5px;
        padding-bottom:5px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .tabwidth{
        width: calc(100% - 0px - 0px) !important;
        margin-top:2vh;

    }
    .cltabjsd{
        width: calc(88% - 0px - 10px) !important;

    }
    .cltab{
        margin-left:0;
        margin-right:0;
        width: calc(100% - 0px - 0px) !important;


    }
    .zlicon{
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center;
        height:14vh;
    }
    .updatezl{
        font-size:17px;
        color:#000000;
        line-height:14vh;
        vertical-align: middle;
    }
    .hcleft{
        width:49vw;
        font-size:20px;
        color:#000000;
        line-height:14vh;
        vertical-align: middle;
        font-weight: bolder;
        padding-left:3vw;
    }
    .copyright{
        width: calc(88% - 0px - 10px) !important;
        font-size:12px;
        text-align: center;
        margin-top:5vh;
        color:#B6B6B6;
    }
    .mleftmargin{
        margin-left:1vw;
    }

    .shouye{

    }
    .sybtn{
        width:100px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .btnnodiv{
        margin-left:1vw;
    }
    .btnno{
        width:50px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }

    .commonmtop{
        margin-top:2vh;
    }
    .btnmargin{
        margin-top:1vh;
    }
    .btnstyle{
        width:100px;
        height:35px;
        background-color: #42B134;
        color:#FFFFFF;
        border-radius: 5px;
    }
    .operbtn{
        margin-left:8vw;
        color:#E10000;
    }
    .checizuowei{
        margin-left:5vw;
    }
    .colorstyle{
        color:#F49200;
        font-weight: bolder;
    }
    .checileixing{
        margin-left:5vw;
    }
    .checimoney{
        margin-left:5vw;
    }
    .zwleixing{
        margin-top:0.1vh;
    }
    .cmtopzw{
        margin-top:2vh;
    }
    .cxinfo{
        margin-left:2vw;
    }
    .endcheci{
        margin-left:3vw;
    }
    .sleft{
        margin-left:1vw;
    }
    .scheci{
        margin-left:2vw;

    }
    .startzhan{
        margin-left:0.5vw;
    }
    .scdiv{
        margin-top:2vh;
    }
    .sccdate{
        font-size:20px;
        font-weight: bolder;
        margin-left:1vw;
    }
    .haoshi{
        margin-left:3vw;
    }
    .tcontentdttwo{
        background-color:#F1F1F1;
        height:260px;
        margin-top:2vh;
        width: calc(100% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        font-size: 14px;

    }
    .tcontentdt{
        background-color:#F1F1F1;
        height:60px;
        margin-top:2vh;
        width: calc(100% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        align-items: center;
    }
    .tcontent{
        background-color:#F1F1F1;
        height:60px;
        margin-top:2vh;
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        align-items: center;

    }
    .tbcontentinfo{
        width: calc(88% - 0px - 10px) !important;

    }
    .tcontentpwd{
        background-color:#F1F1F1;
        height:17vh;
        margin-top:2vh;
        width: calc(88% - 0px - 10px) !important;
        padding-top:2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .mleftd{
        margin-left:-2vw;
    }
    .querybtn{
        background-color:#42B134;
        color:#FFFFFF;
        border-radius: 5px;
        margin-left:-1vw;
    }
    .formstylegrzx{
        padding-top:2vh;

    }
    .formstylegrzx .inputstyle{
        width:10vw;
    }
    .container{

    }
    .header{
        font-size:12px;
    }
    .flex{
        display:flex;
    }
    .weiziicon{

    }
    .pleft{
        margin-left:0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }
    .hc{
        background-color: #3DAF2E;
        width: calc(88% - 0px - 10px) !important;
        height:14vh;
        margin-top:2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    button:hover {background-color: #42B134;color:#FFFFFF;}


    /* 修改标签的背景色 */
    .tabwidth /deep/ .el-tabs__header {
        border:0;
    }

    /* 修改选项卡栏的文本颜色 */
    .tabwidth /deep/ .el-tabs__item {
        color: #000000;
        border:0;
        margin-left:10px;
        margin-right:10px;
    }

    /* 修改选中的选项卡的背景色和边框颜色 */
    .tabwidth /deep/ .el-tabs__item.is-active {
        /*background-color: #409EFF;*/
        /*color: #fff;*/
        /*border-color: #409EFF;*/
        border-bottom:2px solid #22AC38;

    }

    /* 修改分隔线颜色 */
    .tabwidth /deep/ .el-tabs__nav-wrap::after {
        background-color: #fff;
    }

    .tabwidth /deep/ .el-tabs__header .el-tabs__nav{
        border:0;
    }
    .spanstyles{
        width:5vw;
        display: inline-block;
        margin-left:20px;
    }
</style>
