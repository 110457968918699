<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>常用旅客</span>
            </div>
        </div>
        <div class="hc">

            <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstyle"
                style="margin-left:1vw;">

                <el-form-item class="mttop">
                    <el-col :span="20">
                        <el-button class="addbtn" @click="gotoaddpg"
                            style="background-color: #f1f1f1;color:#000000;font-size:14px;">+新增</el-button>
                    </el-col>
                </el-form-item>

            </el-form>
            <div class="cpgcontent ">
                <el-row :gutter="20">
                    <el-col :span="12" v-for="(item, index) in list" :key="index">
                        <div class="pgcontent">
                            <div class="pcdiv flex" v-if="item.deptname!=null && item.deptname!=''">
                                <div>部门：</div>
                                <div>{{ item.deptname }}</div>
                            </div>
                            <div class="pcdiv flex">
                                <div>姓名：</div>
                                <div>{{ item.xingming }}</div>
                            </div>
                            <div class="pcdiv flex">
                                <div>手机号码：</div>
                                <div>{{ item.pgPhone }}</div>
                                <div class="updatebtn" @click="forwardupdateInfo(item.pgId)">修改</div>
                            </div>
                            <div class="pcdiv flex">
                                <div>性别：</div>
                                <div>{{ item.xingbie == '1' ? '男' : item.xingbie == '2' ? '女' : '' }}</div>
                            </div>
                            <div class="pcdiv flex">
                                <div>人员类型：</div>
                                <div>{{ item.personType == '1' ? '成人' : item.personType == '2' ? '儿童' : '' }}</div>
                            </div>
                            <div class="pcdiv flex">
                                <div>证件类型：</div>
                                <div>{{ item.idType == '1' ? '身份证' : item.idType == '2' ? '护照' : '' }}</div>
                            </div>
                            <div class="pcdiv flex">
                                <div>证件号码：</div>
                                <div>{{ item.idNumber }}</div>
                            </div>
                            <div class="pcdiv flex">
                                <div>证件照片：</div>
                                <div v-if="item.idImgZhengmian != null" class="imgzhengmian">
                                    <img :src="item.idImgZhengmian" class="weiziicon imgstyles" />

                                </div>
                                <div v-if="item.idImgFanmian != null" class="imgfanmian">
                                    <img :src="item.idImgFanmian" class="weiziicon imgstyles" />
                                    <!--<el-image class="weiziicon" :src="require(item.idImgFanmian)"-->
                                    <!--fit="cover"></el-image>-->
                                </div>
                            </div>
                            <div class="pcdiv btn">
                                <div class="delbtn" @click="handelDel(item.pgId)">删除</div>
                            </div>

                        </div>

                    </el-col>
                </el-row>


            </div>


            <div class="pager flex">
                <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                    :total="pagination.total"></el-pagination>
            </div>

            <div class="copyright">
                工业和信息化部备案管理系统网站 豫B1-20080191-28
            </div>

        </div>

    </div>
</template>

<script>
import ElImage from "element-ui/packages/image/src/main";
import { delPassenger, querypage } from '../api/index';
import Cookie from 'js-cookie';
export default {
    components: {
        ElImage
    },
    data() {
        return {

            form: {
                date1: "",
                sendcity: "",
                arrivalcity: "",
            },
            rules: {
                mobilephone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                ],

                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],

            },
            list: [],
            pagination: {
                curpage: 1,
                pagesize: 10,
                total: 0,
            },
        }
    },
    activated() {
        this.getPassengeriList();
    },
    name: "passenger-right",
    methods: {
        //转向修改常用旅客信息
        forwardupdateInfo(pgId){
            this.$router.push({path:'/passengerupdate',query:{pgId:pgId}});
        },
        //转向新增常用旅客
        gotoaddpg() {
            this.$router.push('/passengeradd');
        },
        handleCurrentChange(val) {
            this.pagination.curpage = val;
            this.getPassengeriList();
        },
        search() {
            this.pagination.curpage = 1;
            this.getPassengeriList();
        },
        getPassengeriList() {

            //获取手机号：
            const mobilePhone = Cookie.get('phoneKey');
            const params = {
                curpage: this.pagination.curpage,
                pagesize: this.pagination.pagesize,
                mobilePhone: mobilePhone
            }
            const p = this.$querystring.stringify(params);
            this.loading = true;
            querypage(p).then(res => {
                this.loading = false;
                this.list = res.data.data.data;
                this.pagination.total = res.data.data.totalpage;

            })


        },
        handelDel(id) {
            this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                const res = await delPassenger({ id: id });

                if (res.data.code === 200) {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    this.getPassengeriList();
                } else {
                    this.$message({
                        type: "error",
                        message: `删除失败`,
                    });
                }
            })
                .catch(() => { });
        },

    },

}
</script>

<style scoped>
.imgstyles {
    width: 100px;
    height: 60px;
}

.defaultbtn {
    background-color: #42B134;
    color: #FFFFFF;
}

.copyright {
    width: calc(88% - 0px - 10px) !important;
    font-size: 12px;
    text-align: center;
    margin-top: 5vh;
    color: #B6B6B6;
}

.addbtn {
    width: 150px;
    height: 45px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.weiziiconst {
    width: 24px;
    height: 24px;
}

.cdiv {}

.mleftmargin {
    margin-left: 1vw;
}

.pager {
    margin-top: 10vh;
    margin-left: 0vw;
    width: calc(88% - 0px - 10px) !important;
    display: flex;
    justify-content: center;
}

.shouye {}

.sybtn {
    width: 100px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.btnnodiv {
    margin-left: 1vw;
}

.btnno {
    width: 50px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.cpgcontent {
    margin-top: 1vh;

}

.delbtn {
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 5vw;
    text-align: center;
    margin-left: 20vw;
    margin-top: 1vh;
}

.imgzhengmian {}

.imgfanmian {
    margin-left: 1vw;
}

.updatebtn {
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 5px;
    margin-left: 1vw;
    padding-left: 10px;
    padding-right: 10px;
}

.pgcontent {
    height: 350px;
    background-color: #F1F1F1;
    font-size: 13px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
    position: relative;
}

.pcdiv {
    padding-top: 1vh;
    font-size: 14px;
}

.pcdiv.btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.mleftd {
    margin-left: -2vw;
}

.querybtn {
    background-color: #42B134;
    color: #FFFFFF;
    border-radius: 5px;
    margin-left: -1vw;
}

.formstyle {
    padding-top: 2.2vh;
}

.container {}

.header {
    font-size: 12px;
}

.flex {
    display: flex;
}

.weiziicon {}

.pleft {
    margin-left: 0.5vw;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.hc {
    width: calc(88% - 0px - 10px) !important;
    height: 100vh;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

button:hover {
    background-color: #42B134;
    color: #FFFFFF;
}
</style>
