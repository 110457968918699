<template>
  <div class="container">
    <div class="login-page">
      <div class="logo cleftmargin">

      </div>
      <div class="cjtitle cleftmargin">
        创建新账户
      </div>
      <div class="yyaccount">
          已有账户？<span class="ljlogin" title="马上登录" @click="gotologin">马上登录</span>
      </div>
      <div class="regformreg">

          <el-form ref="form"  :rules="rules" class="formstyle" label-position="top"  :model="form" label-width="30%">
                <el-form-item  label="手机号" class="mttop labelstyle" prop="mobilephone">
                    <el-input class="inputstyle heightstyle"  v-model="form.mobilephone" clearable></el-input>
                </el-form-item>
              <el-form-item label="验证码"  class="mttopinfo labelstyle" prop="checkcode">
                  <el-input class="checkcodes"  v-model="form.checkcode" clearable></el-input>
                  <el-button  class="sendmsgcodeinfo" style=" background: #42B134;color:#FFFFFF;"
                             @click="getCode"
                             :disabled="!login.canGet"
                             type="button">
                      <span v-show="!login.canGet">{{login.waitTime+"s"}}后重发</span>
                      <span v-show="login.canGet">{{tempLogin.text}}</span>
                  </el-button>
              </el-form-item>
              <el-form-item label="密码"  class="mttopinfo labelstyle"  prop="pwd">
                  <el-input  show-password class="inputstyle"  v-model="form.pwd" clearable></el-input>
              </el-form-item>
              <el-form-item label="单位(选填)"  class="mttopinfo labelstyle"  prop="danwei">
                  <el-input class="inputstyle"  v-model="form.danwei" clearable></el-input>
              </el-form-item>
              <el-form-item label="部门(选填)"  class="mttopinfo labelstyle"  prop="dept">
                  <el-input class="inputstyle"  v-model="form.dept" clearable></el-input>
              </el-form-item>
              <el-form-item >
                  <el-button class="el-button--inner" style="background-color: #42B134;color:#FFFFFF;border:1px solid #42B134;" @click="submitReg" title="立即注册">立即注册</el-button>
              </el-form-item>
          </el-form>

      </div>
    </div>
  </div>
</template>
<script>
    import Cookie from 'js-cookie';
    import { register,smsCode,querycompanyexitsinfo,getComdeptdata } from '../api/index';
export default {
  components: {

  },
  watch: {

  },
  created () {

  },
  data () {
    return {
        tempLogin: { // 定义一个临时对象
            canGet: true,
            timer: null,
            waitTime: 60,
            text: '发送验证码'
        },
        form: {
            mobilephone: "",
            checkcode: "",
            pwd:"",
            danwei:"",
            dept:"",
        },
        rules: {
            mobilephone: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
            ],
            checkcode: [
                { required: true, message: '请输入验证码', trigger: 'blur' },
            ],
            pwd: [
                { required: true, message: '请输入密码', trigger: 'blur' },
            ],

        }
    }
  },
    computed: {
        login () { // 最终对象
            const _this = this
            if (!this.tempLogin.canGet) {
                if (this.tempLogin.waitTime === 0) {
                    _this.tempLogin.text = '重新发送'
                }
                return this.timeCountdown(this.tempLogin)
            } else {
                return this.tempLogin
            }
        },
    },
    activated(){
       this.getcomdeptdata();
    },
  methods: {
      // 获取单位部门
      getcomdeptdata(){
          getComdeptdata().then(res => {
              if (res.data.code && res.data.code === 200) {
                  this.comdeptdata=res.data
              }
          })
      },
      
      //注册提交
      submitReg(){
          var mobilephonestr=this.form.mobilephone;
          var checkcodestr=this.form.checkcode;
          var pwdstr=this.form.pwd;
          var danweistr=this.form.danwei;
          var deptstr=this.form.dept;
          if(""!=danweistr && null!=danweistr){
             //根据单位名查询是否注册过
              const params = {
                  danwei:danweistr,
                  deptName: deptstr
              }
              querycompanyexitsinfo(params).then(res => {
                  if (res.data.code && res.data.code === 200) {
                     this.submitReginfo();
                  }else{
                      var  msgstr=res.data.msg;
                      this.$message.error('异常:'+msgstr);
                  }
            })
          }else{
              this.submitReginfo();
          }
      },
      //提交注册
      submitReginfo(){
          var mobilephonestr=this.form.mobilephone;
          var checkcodestr=this.form.checkcode;
          var pwdstr=this.form.pwd;
          var danweistr=this.form.danwei;
          var deptstr=this.form.dept;
          this.$refs["form"].validate((val) => {
              if (val) {
                  const params = {
                      mobilePhone: mobilephonestr,
                      memPwd: pwdstr,
                      checkCode:checkcodestr,
                      danwei:danweistr,
                      deptName: deptstr,
                      memStatus:'Y',
                      bindStatus:'Y',
                  }
                  //const p = this.$querystring.stringify(params);
                  register(params).then(res => {
                      if (res.data.code && res.data.code === 200) {
                      this.$message.success('注册成功');
                      //this.$emit('loadingFunt', false);
                      this.$router.push('/login');
                  }else{
                      var  msgstr=res.data.msg;
                      this.$message.error('注册失败:'+msgstr);
                      //this.$emit('loadingFunt', false);

                  }
              })
              } else {
                  return false;
      }
      });
      },
      //转向登录
      gotologin(){
          this.$router.push('/login');
      },
      //发送短信验证码
      getCode(){
          var  mobilephonestr=this.form.mobilephone;
          if(""==mobilephonestr || null==mobilephonestr){
              this.$message.warning('请输入手机号！');
              return;
          }
          const params = {
              mobile: mobilephonestr,
              event:'register'
          }
          //调用短信发送接口
          smsCode(params).then(res => {
              if (res.data.code && res.data.code === 1) {
               this.$message.success('发送成功');
              }else{
               var  msgstr=res.data.msg;
               this.$message.error('发送失败:'+msgstr);
              }
          })
          this.timeCountdown(this.login) // 参数为最终对象
      },
      timeCountdown (obj) {
          // obj包括timer、waitTime 、canGet
          const TIME_COUNT = 60 // 默认倒计时秒数
          if (!obj.timer) {
              obj.waitTime = TIME_COUNT
              obj.canGet = false
              obj.timer = setInterval(() => {
                  if (obj.waitTime > 0 && obj.waitTime <= TIME_COUNT) {
                  obj.waitTime--
              } else {
                  obj.canGet = true
                  clearInterval(obj.timer) // 清空定时器
                  obj.timer = null
              }
          }, 1000)
          }
          return {
              timer: obj.timer,
              canGet: obj.canGet,
              waitTime: obj.waitTime
          }
      }
  }
}
</script>
<style lang="less">
    .mttopinfo{
        margin-top:-1.3vh;
    }
    .regformreg .labelstyle .el-form-item__label{
        font-size:18px;
        font-family: 'MicrosoftYaHei';
        color:#000000;
        margin-top:1vh;
    }
    .regformreg .el-form-item__label{
        line-height:25px;
    }
    .regformreg .formstyle{
        font-family: "MicrosoftYaHei";
        color:#000000;
    }
  //   .nowreginfo{
  //       width:290px;
  //       height:40px;
  //       background-color: #42B134;
  //       border-radius: 7px;
  //       color:#FFFFFF;
  //       text-align: center;
  //       cursor:pointer;
  //   }
    .el-button--inner{
        width:100%;
        height:40px;
        background-color: #42B134;
        border-radius: 7px;
        color:#FFFFFF;
        text-align: center;
        cursor:pointer;
        border:1px solid #42B134;
    }

    .sendmsgcodeinfo{
        width: 120px;
        height: 40px;
        background: #42B134;
        border-radius: 7px;
        color:#FFFFFF;
        text-align: center;
        margin-left:10px;
        padding:10px;
        padding-left:20px;
        padding-right:20px;
        cursor:pointer;
    }
    .checkcodes{
        width:215px;
        border-radius: 10px;
    }
    .regformreg .el-input__inner {
        border-color: #42B134;
        border-radius: 7px;
    }

    .regformreg .el-input__inner:focus {
        border-color: #42B134;
        border-radius: 7px;
    }

  .regformreg{
      margin-top:3vh;

  }
  // .ljlogin{
  //   color:#42B134;
  //   font-family: 'MicrosoftYaHei';
  //   cursor:pointer;
  // }
  // .yyaccount{
  //   margin-left:40vw;
  //   color:#000000;
  //   font-family: 'MicrosoftYaHei';
  //     font-size:18px;
  //   margin-top:1vh;
  // }
  // .cleftmargin{
  //   margin-left:35vw;
  // }
  // .cjtitle{
  //   font-size:36px;
  //   color:#42B134;
  //   font-weight: Bold;
  //     font-family: 'MicrosoftYaHei-Bold';
  //   padding-top:15vh;
  //   padding-left:5vw;
  // }
  // .container{
  //    background-image:url(../assets/img/bg.png);
  //    background-repeat: no-repeat;
  //    width:100%;
  //   min-height: 100vh;
  //   background-size: 100% 100%;

  // }
  // .logo{
  //   position: absolute;
  //   background-image:url(../assets/img/logo.png);
  //   background-repeat: no-repeat;
  //   width:185px;
  //   height:72px;
  //   margin-left:40vw;
  //   margin-top:20px;
  //   z-index:2000;

  // }
  // .box-card{

  // }
</style>