<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>火车票预订</span><span>>预订</span>
            </div>
        </div>
        <div class="addpgstyle">添加乘客方式</div>
        <div class="tcontentxc flex">
            <div class="commonmtop">
                <div class="sccdate flex">
                    <div class="startzhan">{{ fromStation }}</div>
                    <div class="iconstyles">
                        <el-image style="margin-left: 1vw" class="weiziicon" :src="require('../assets/img/shi.png')"
                            fit="cover"></el-image>
                    </div>
                </div>
                <div class="scdiv flex startweight bluecolor">
                    {{ fromTime }}
                </div>
            </div>
            <div class="haoshi commonmtops">
                <div class="cxinfo">{{ trainCode }}</div>
                <div class="mtopsinfo">
                    <el-image class="weiziicon" :src="require('../assets/img/xian.png')" fit="cover"></el-image>
                </div>
                <div class="sleft smargintop">
                    {{ formateHour(runTime) }}小时{{ runTime.slice(3, 5) }}分
                </div>
            </div>
            <div class="endcheci commonmtop">
                <div class="sccdate flex">
                    <div class="startzhan">{{ toStation }}</div>
                    <div class="iconstyles">
                        <el-image style="margin-left: 1vw" class="weiziicon" :src="require('../assets/img/zhong.png')"
                            fit="cover"></el-image>
                    </div>
                </div>
                <div class="scdiv flex startweight bluecolor">
                    {{ toTime }}
                </div>
            </div>
        </div>
        <Commuter ref="commuterRef" />
        <div class="remark">备注</div>
        <div class="remarkinfo">
            <el-input type="textarea" v-model="remarkTkorder" maxlength="500" show-word-limit resize="none" :rows="5"
                class="textarea-box" placeholder="请输入备注信息"></el-input>
        </div>
        <div class="addpg">
            <el-button class="orderbtn" :disabled="logLoading" @click="submitorder">提交订单</el-button>
        </div>
        <div class="shuoming">
            <div>说明</div>
            <div>
                备注出票要求，若满足则出票，不满足则进入特修孜订单西时关注训单状念和公众号得
            </div>
            <div>
                关于行程：(可出5月1日-5月3日)、(车次备选Z161、T146、G508)、(可出始发站重庆)。(可出终点站上海)、(一等二等均可)
            </div>
            <div>
                关于席位：靠窗、靠过道，优先DF(若无则A8C均可)、中下、同车、一个包、张三商务座、李四靠密
            </div>
        </div>

        <div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>

    </div>
</template>

<script>
import ElImage from "element-ui/packages/image/src/main";
import ElButton from "element-ui/packages/button/src/button";
import Commuter from "./Commuter.vue";
import { addOrderInfo } from "@/api";
import Cookie from 'js-cookie';
export default {
    components: {
        ElButton,
        ElImage,
        Commuter,
    },
    data() {
        return {
            pgname: "",
            pgshow: false,
            dialogbgsryVisible: false,
            dialogcypgVisible: false,
            dialoggatxzVisible: false,
            dialogsfzVisible: false,
            logLoading: false,
            dialogVisible: false,
            textareaValue: "",
            fromStation: "",
            trainCode: "",
            fromTime: "",
            runTime: "",
            toStation: "",
            toTime: "",
            rq: "",
            orderMoney: "",
            remarkTkorder: "",
            pginfo: "",
            form: {
                date1: "",
                sendcity: "",
                arrivalcity: "",
                cardimg: "",
            },
            ruleForm: {
                passengersList: [
                    {
                        rytype: "",
                        user: "",
                        idtype: "",
                        id: "",
                        zuoxi: "",
                        isbaoxian: "",
                    },
                ],
            },
            rules: {
                mobilephone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
                ],

                pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            fileList: [],
            companyList: [],
            defaultUserList: [],
            seat: []
        };
    },
    activated() {
        const trainCodeStr = this.$route.query.trainCode;
        const fromStationstr = this.$route.query.fromStation;
        const fromTimestr = this.$route.query.fromTime;
        const runTimestr = this.$route.query.runTime;
        const toStationstr = this.$route.query.toStation;
        const toTimestr = this.$route.query.toTime;
        this.fromStation = fromStationstr;
        this.trainCode = trainCodeStr;
        this.fromTime = fromTimestr;
        this.runTime = runTimestr;
        this.toStation = toStationstr;
        this.toTime = toTimestr;
        this.rq = this.$route.query.date
        this.orderMoney = this.$route.query.orderMoney
        this.textareaValue = ""
        this.seat = this.$route.query.seat ? this.$route.query.seat.split("-") : []

    },
    created() {
    },
    methods: {
        formateHour(runTime) {
            if (runTime && runTime[0] === '0') {
                return runTime.substr(1, 1)
            }
            return runTime.substr(0, 2)
        },

        validateSfz(value) {
            if (!value || value.length !== 18) {
                // this.$message.error('请输入正确的身份证号');
                return false
            }
            return true
        },
        async submitorder() {
            if (this.logLoading) return
            this.logLoading = true;
            const passengersList = this.$refs.commuterRef.ruleForm.passengersList;
            const user = Cookie.get('phoneKey');
            const  danweinamestr=Cookie.get('danweinameKey');
            // if (user) {
            //     this.user = user;
            // }
            const params = {
                danweiname:danweinamestr,
                departureStation: this.fromStation,
                arrivalStation: this.toStation,
                trainNumber: this.trainCode,
                senddate: this.rq + ' ' + this.fromTime,
                tkNum: passengersList.length,
                remarkTkorder: this.remarkTkorder,
                orderMoney: this.orderMoney,
                runtime: this.runTime,
                arrivaldate: this.rq + ' ' + this.toTime,
                createperson: user || '',
                pgList: {
                    xingming: [],
                    renyuanLeixing: [],
                    cardType: [],
                    cardId: [],
                    xizuo: [],
                    isbuybaoxian: [],

                },
                pgcount: passengersList.length,
            }

            try {

                // 判断重复的身份证
                const idList = {}

                passengersList.forEach(item => {
                    const rytypestr = item.rytype;
                    const xingmingstr = item.user;
                    if ("" == rytypestr || null == rytypestr) {
                        throw new Error("请选择人员类型");
                    }
                    if ("" == xingmingstr || null == xingmingstr) {
                        throw new Error("请输入姓名");
                    }
                    if (!item.id || (!this.validateSfz(item.id) && item.idtype === '1')) {
                        throw new Error("请输入正确身份证号");
                    }
                    if (!item.idtype) {
                        throw new Error("请选择证件类型");
                    }
                    if (!item.zuoxi) {
                        throw new Error("请输入席座");
                    }
                    if (idList[item.id + '_' + item.idtype]) {
                        throw new Error(`身份证${item.id}重复`);
                    }
                    idList[item.id + '_' + item.idtype] = true
                    params.pgList.xingming.push(xingmingstr);
                    params.pgList.renyuanLeixing.push(rytypestr);
                    params.pgList.cardId.push(item.id);
                    params.pgList.cardType.push(item.idtype);
                    params.pgList.xizuo.push(item.zuoxi);
                    params.pgList.isbuybaoxian.push(item.isbaoxian ? '1' : '2');
                })
            } catch (error) {
                this.$message.error(error.message);
                return;
            }
            finally {
                this.logLoading = false
            }

            const data = await addOrderInfo(params)
            if (data && data.data && data.data.code === 200) {
                this.$message.success('保存成功')
                this.$router.push({
                    path: '/trainorder'
                })
            }
            else {
                this.$message.error('保存失败')
            }
        },
    },
    name: "trainyudingshenqing-right",
};
</script>

<style scoped>
.smargintop {
    margin-top: 15px;
}

.mtopsinfo {
    margin-top: 10px;
    height: 1px;
}



.remarkinfo {
}

.weiziiconst {
    width: 24px;
    height: 24px;
}


.cdiv {
    /*margin-top:30px;*/
}

.shuoming {
    font-size: 13px;
    color: #444444;
    line-height: 25px;
}

.textarea-box {
    width: calc(100% - 0px - 0px) !important;
    background-color: #f1f1f1;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.textarea-box /deep/ .el-textarea__inner {
    background-color: #f1f1f1;
}

.remark {
    font-weight: bolder;
}

.orderbtn {
    width: 350px;
    height: 45px;
    border: 1px solid #42b134;
    color: #ffffff;
    padding: 10px;
    margin-top: 5vh;
    background-color: #42b134;
}




.btnoper {}

.bluecolor {
    color: #42b134;
}

.startweight {
    font-weight: bolder;
    font-size: 20px;
}

.iconstyles {}

.addpgstyle {
    font-weight: bolder;
    margin-top: 2vh;
}

.copyright {
    width: calc(88% - 0px - 10px) !important;
    font-size: 12px;
    text-align: center;
    margin-top: 5vh;
    color: #b6b6b6;
}

.mleftmargin {
    margin-left: 1vw;
}

.pager {
    margin-top: 5vh;
    margin-left: 10vw;
}

.shouye {}

.sybtn {
    width: 100px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.btnnodiv {
    margin-left: 1vw;
}

.btnno {
    width: 50px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.commonmtops {
    margin-top: 15px;
}

.commonmtop {
    margin-top: 20px;
}

.btnmargin {
    margin-top: 1vh;
}

.btnstyle {
    width: 100px;
    height: 35px;
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
}

.operbtn {
    margin-left: 8vw;
}

.checizuowei {
    margin-left: 5vw;
}

.colorstyle {
    color: #f49200;
    font-weight: bolder;
}

.checileixing {
    margin-left: 5vw;
}

.checimoney {
    margin-left: 5vw;
}

.zwleixing {
    margin-top: 0.1vh;
}

.cmtopzw {
    margin-top: 2vh;
}

.cxinfo {}

.endcheci {
    margin-left: 3vw;
}

.sleft {}

.scheci {
    margin-left: 2vw;
}

.startzhan {
    margin-left: 0.5vw;
}

.scdiv {
    margin-top: 10px;
    margin-left: 1vw;
}

.sccdate {
    font-size: 20px;
    font-weight: bolder;
}

.haoshi {
    margin-left: 3vw;
    width: 462px;
    justify-content: center;
    justify-items: center;
    text-align: center;
}

.tcontentxc {
    background-color: #f1f1f1;
    height: 100px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
}

.tcontent {
    background-color: #f1f1f1;
    height: 20vh;
    margin-top: 2vh;
    width: calc(88% - 0px - 10px) !important;
    padding-top: 4vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mleftd {
    margin-left: -2vw;
}

.addpg {
    text-align: center;
}

.querybtn {
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
    margin-left: -1vw;
}

.formstyleyd {
    padding-top: 2.2vh;
}

.container {}

.header {
    font-size: 12px;
}

.flex {
    display: flex;
}

.weiziicon {}

.pleft {
    margin-left: 0.5vw;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}
</style>
