import { render, staticRenderFns } from "./GonggaolistRight.vue?vue&type=template&id=3da76d56&scoped=true"
import script from "./GonggaolistRight.vue?vue&type=script&lang=js"
export * from "./GonggaolistRight.vue?vue&type=script&lang=js"
import style0 from "./GonggaolistRight.vue?vue&type=style&index=0&id=3da76d56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da76d56",
  null
  
)

export default component.exports