<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')"
                          fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>公告信息</span>
            </div>
        </div>
        <div class="hc">
        <el-form
                size="small"
                :inline="true"
                ref="form"
                :model="form"
                label-width="80px"
                class="formstylegg"
        >
            <el-form-item  class="mttop mleftstyle" prop="titlename">
                    <el-input  style="width:40vw;line-height: 37px"  placeholder="请输入名称搜索"  v-model="form.titlename" clearable></el-input>
            </el-form-item>
            <el-form-item  class="mttop" >
                    <el-button class="savebtn" @click="searchgg" style="background-color: #42B134;color:#FFFFFF;">搜索</el-button>
            </el-form-item>
        </el-form>
        </div>
        <div class="heightstyle">
            <div class="tcontent flex" v-for="(item,index) in listgg" :key="index" @click="forwardggdetails(item.ggId)">
                <div class="scheci " >
                   {{item.ggTitle}}
                </div>

                <div class="checimoney " style="color:#999999;">
                    发布时间：{{item.ggDate.slice(0,10)}}
                </div>

                <div class="operbtn">
                    浏览次数：{{item.ggCount}}
                </div>
            </div>
        </div>
        <div class="pager flex">
            <div class="shouye">
                <el-button  :class="indexbtn?'cgbtn':'sybtn'"  @click="forwardindex">首页</el-button>
            </div>
            <div class="btnnodiv flex" v-for="(item1,index1) in totalpages" :key="index1" @click="forwardpage(item1,index1)">
                <el-button  v-if="btnflag==false" :class="isActive==index1?'defaultbtn':'btnno'" >{{item1}}</el-button>
                <el-button  class="btnno"  v-else>{{item1}}</el-button>
            </div>

            <div class="shouye mleftmargin">
                <el-button  :class="lastbtn?'cgbtn':'sybtn'" @click="forwardlast">末页</el-button>
            </div>
        </div>
        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
    </div>
</template>

<script>
    import ElImage from "element-ui/packages/image/src/main";
    import ElCard from "element-ui/packages/card/src/main";
    import ElButton from "element-ui/packages/button/src/button";
    import { querygg } from '../api/index';
    export default {
        components: {
            ElCard,
            ElImage,
            ElButton
        },
        data () {
            return {
                lastbtn:false,
                indexbtn:false,
                btnflag:false,
                isActive:0,
                loading:false,
                listgg:[],
                totalpages:0,
                pagesize:10,
                form: {
                    titlename: ""
                },
            }
        },
        activated(){
            this.querygonggao(0,this.pagesize);
        },
        methods: {
            //转向公告详细页面
            forwardggdetails(idstr) {
                this.$router.push({path:'/gonggaodetails',query:{ggId:idstr}});
            },
            //转向首页
            forwardindex(){
                this.btnflag=true;
                this.indexbtn=true;
                this.lastbtn=false;
                this.querygonggao(0, this.pagesize);
            },
            //转向尾页
            forwardlast(){
                this.indexbtn=false;
                this.btnflag=true;
                this.lastbtn=true;
                this.querygonggao(this.totalpages, this.pagesize);
            },
            //转向跳转页数
            forwardpage(page,index1){
                this.lastbtn=false;
                this.btnflag=false;
                this.indexbtn=false;
                this.isActive =index1 ;
                if(page>this.totalpages){
                    this.$message.warning('当前页应小于总页数');
                }else {
                    this.querygonggao(page, this.pagesize);
                }
            },
            //搜索公告信息
            searchgg(){
                const titlenamestr=this.form.titlename;
                if(undefined==titlenamestr || null==titlenamestr){
                    titlenamestr="";
                }
                const params = {
                    curpage: 0,
                    pagesize: 10,
                    ggTitle:titlenamestr
                }
                const p = this.$querystring.stringify(params);
                this.loading=true;
                querygg(p).then(res => {
                    this.loading=false;
                    this.listgg=res.data.data.data;
                 })
            },
            //查询公告信息
            querygonggao(curpagestr,pagesizestr){
                const params = {
                    curpage: curpagestr,
                    pagesize: pagesizestr,
                }
                const p = this.$querystring.stringify(params);
                this.loading=true;
                querygg(p).then(res => {
                    this.loading=false;
                    this.listgg=res.data.data.data;
                    this.totalpages=res.data.data.totalpage;

                })
            },
        },
        name: "gonggaolist-right"
    }

</script>

<style scoped>
    .heightstyle{
        min-height:45vh;
    }
    .searchinfo .el-input__inner{

    }
    .searchinfo .el-input{
        height:40vh;
    }
    .weiziiconst{
        width:24px;
        height:24px;
    }
    .formstylegg{
        padding-top:2vh;
        background-color: #FFFFFF;

    }
    .formstylegg .el-input__inner{
        height:45px;
        line-height: 45px;
        background-color: rgba(241, 241, 241, 1);

    }


    .cdiv{

    }
    .copyright{
        width:63vw;
        font-size:12px;
        text-align: center;
        margin-top:5vh;
        color:#B6B6B6;
    }
    .pager{
        height:35px;
        margin-top:5vh;
        margin-left:0vw;
        width: calc(88% - 0px - 10px) !important;
        display: flex;
        justify-content: center;
    }
    .shouye{

    }
    .cgbtn{
        width:100px;
        height:35px;
        background-color: #42B134;
        color:#FFFFFF;
        border:1px solid #666666;
        text-align: center;
    }
    .sybtn{
        width:100px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .btnnodiv{
        width:50px;
        height:35px;
        margin-left:1vw;
    }
    .btnno{
        width:50px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }

    .cpgcontent{
        margin-top:1vh;
    }
    .operbtn{
        margin-left:8vw;
        color:#999999;
        font-size:14px;
    }
    .checimoney{
        margin-left:5vw;
        font-size:14px;
    }
    .scheci{
        margin-left:1vw;
        font-size:16px;
        width:450px;
    }
    .commonmtop{
        margin-top:2vh;
    }
    .tcontent{
        background-color:#F1F1F1;
        height:80px;
        margin-top:1vh;
        align-items: center;
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;


    }
    .savebtn{
        width:200px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        background-color:#42B134;
        color:#FFFFFF;
        text-align: center;
        padding-top:11px;
        padding-bottom:11px;
        padding-left:10px;
        padding-right:10px;

    }
    .hc{
        width:63vw;
        margin-top:2vh;
        height:12vh;

    }
    .container{

    }
    .mleftstyle{
        margin-left:5vw;
    }
    .searchwidth{
        width: calc(88% - 0px - 10px) !important;
        height:30px;
        padding:5px;
    }
    .header{
        font-size:12px;
    }
    .flex{
        display:flex;
    }
    .weiziicon{

    }
    .pleft{
        margin-left:0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }
    .mleftmargin{
        margin-left:1vw;
    }
    button:hover {background-color: #42B134;color:#FFFFFF;}
    .defaultbtn{
        background-color: #42B134;
        color:#FFFFFF;
    }
</style>