<template>
    <div class="container">
        <div class="login-page">
            <div class="logo cleftmargin">

            </div>
            <div class="cjtitle cleftmargin">
                欢迎登录
            </div>
            <div class="yyaccount">
                还没有账号？<span class="ljlogin" title="立即注册" @click="forwardreg">立即注册</span>
            </div>
            <div class="regformlogin">

                <el-form ref="form"  :rules="rules" class="formstyle" label-position="top"  :model="form" label-width="30%">
                    <el-form-item label="手机号" class="mttop labelstyle" prop="mobilephone">
                        <el-input class="inputstyle heightstyle"  v-model="form.mobilephone" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="密码"  class="mttop labelstyle"  prop="pwd">
                        <el-input  show-password class="inputstyle"  v-model="form.pwd" clearable></el-input>
                    </el-form-item>

                    <el-form-item >
                        <el-button class="loginbtn" style=" background-color: #42B134;color:#FFFFFF;" @click="goLogin" title="马上登录">马上登录</el-button>
                    </el-form-item>
                    <el-form-item>
                        <span class="forgetpwd" title="忘记密码" @click="forwardforgetpwd">忘记密码？</span>
                    </el-form-item>
                    <el-form-item>
                        <span class="qitalogin">─────其他登录方式─────</span>
                    </el-form-item>
                    <el-form-item>
                       <el-image class="wxstyle" :src="require('../assets/img/wechat.png')"
                                 fit="cover" @click="gotowxlogin"></el-image>
                    </el-form-item>
                </el-form>

            </div>
        </div>
    </div>
</template>
<script>
    import Cookie from 'js-cookie';
    import ElImage from "element-ui/packages/image/src/main";
    import { denglu } from '../api/index';
    export default {
        components: {
            ElImage
        },
        watch: {

        },
        created () {

        },
        data () {
            return {

                form: {
                    mobilephone: "",
                    checkcode: "",
                    pwd:"",
                    danwei:"",
                    dept:"",
                },
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        // { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],

                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],

                }
            }
        },

        methods: {
            //微信登录
            gotowxlogin(){

            },
            //马上登录
            goLogin(){
                var mobilephonestr=this.form.mobilephone;
                var pwdstr=this.form.pwd;
                this.$refs["form"].validate((val) => {
                    if(val==true){
                        const params = {
                            "account": mobilephonestr,
                            "password": pwdstr,
                        }
                        denglu(params).then(res => {
                         if (res.data.code && res.data.code === 1) {
                                Cookie.set('phoneKey', mobilephonestr);
                                Cookie.set('userinfo', res.data.data.userinfo);
                               //将登录手机号存入session
                                this.$message.success('登录成功');
                                this.form.mobilephone="";
                                this.form.pwd="";
                                this.$router.push('/pageindex');
                            }else{
                                var  msgstr=res.data.msg;
                                this.$message.error('登录失败:'+msgstr);
                            }
                        })
                }
                });
                // this.$router.push('/pageindex');
            },
            //转向忘记密码
            forwardforgetpwd(){
                this.$router.push('/forgetpwd');
            },
            //转向注册
            forwardreg(){
                this.$router.push('/home');
            }
        }
    }
</script>
<style lang="less">
    .login-page{
        width: 345px;
        margin: auto;
        max-width: 90%;
        position: relative;
    }
    .regformlogin .labelstyle .el-form-item__label{
        font-size:18px;
        font-family: 'MicrosoftYaHei';
        color:#000000;
        margin-top:1vh;
    }
    .regformlogin .el-form-item__label{
        line-height:30px;
    }
    .wxstyle{
        width:54px;
        height:54px;
        display: block;
        margin: auto;
    }
    .qitalogin{
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 18px;
        color:#949494;
        display: block;
        margin: 0 auto;
        text-align: center;
    }
    .forgetpwd{
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 18px;
        text-align: right;
        float:right;
        cursor:pointer;
    }
    .regformlogin .formstyle{
        font-family: "MicrosoftYaHei";
        color:#000000;
    }
    .mttop{
        margin-top:-2vh;
    }
    .loginbtn{
        width:100%;
        height:40px;
        background-color: #42B134;
        border-radius: 7px;
        color:#FFFFFF;
        text-align: center;
        cursor:pointer;
    }

    .sendmsgcode{
        width:80px;
        height:30px;
        background-color: #42B134;
        color:#FFFFFF;
        text-align: center;
        margin-left:1vw;
        padding:10px;
        padding-left:20px;
        padding-right:20px;
        cursor:pointer;
    }
    .checkcode{
        width:16%;
        border:1px solid #42B134;
        border-radius: 10px;
    }
    .regformlogin .el-input__inner {
        border-color: #42B134;
        border-radius: 7px;
    }

    .regformlogin .el-input__inner:focus {
        border-color: #42B134;
        border-radius: 7px;
    }
    .inputstyle{
        width: 460px;
        max-width: 100%;
    }
    .regformlogin{
        margin-top:4vh;
    }
    .ljlogin{
        color:#42B134;
        font-family: 'MicrosoftYaHei';
        cursor:pointer;
    }
    .yyaccount{
        color:#000000;
        font-family: 'MicrosoftYaHei';
        margin-top:1vh;
    }
    
    .cjtitle{
        font-size:35px;
        color:#42B134;
        font-weight: bold;
        font-family: 'MicrosoftYaHei-Bold';
        padding-top:15vh;
    }
    .container{
        background-image:url(../assets/img/bg.png);
        background-repeat: no-repeat;
        width:100%;
        min-height: 100vh;
        background-size: 100% 100%;

    }
    .logo{
        position: absolute;
        background-image:url(../assets/img/logo.png);
        background-repeat: no-repeat;
        width:185px;
        height:72px;
        margin-top:20px;
        z-index:2000;

    }
    .box-card{

    }
</style>