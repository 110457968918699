<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')"
                          fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>个人中心</span><span>>基本资料</span>
            </div>
        </div>
        <div class="hc flex">

            <el-form
                    size="small"
                    :inline="true"
                    ref="form"
                    :model="form"
                    label-width="80px"
                    class="formstylejp"
            >

                <el-form-item label=""  class=" fsizestyle" prop="sendcity">

                            <span class="spanstyles">
                                手机号码：{{user}}
                            </span>
                    <span class="spanstyles">
                                注册时间：{{objmem.regDate}}
                            </span>
                </el-form-item>
                <el-form-item label=""  class=" fsizestyle" prop="sendcity">

                            <span class="spanstyles">
                                单位：{{objmem.danwei}}<el-button style="margin-left:10px;">解绑</el-button>
                            </span>

                </el-form-item>
                <el-form-item label=""  class=" fsizestyle" prop="sendcity">

                            <span class="spanstyles">
                                部门：  <el-input class="inputstyle heightstyle" placeholder="请输入部门" v-model="form.deptname"
                                               clearable></el-input>
                                <!--<el-select v-model="form.name" placeholder="选择部门">-->
                                    <!--<el-option label="软件研发部" value="0"></el-option>-->
                                    <!--<el-option label="测试部门" value="1"></el-option>-->
                                <!--</el-select>-->
                            </span>

                </el-form-item>

            </el-form>


        </div>
        <div class="savebtndiv" >
            <el-button class="savebtn" @click="updatemem">保存</el-button>
        </div>


        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
    </div>
</template>

<script>
    import ElImage from "element-ui/packages/image/src/main";
    import ElCard from "element-ui/packages/card/src/main";
    import ElButton from "element-ui/packages/button/src/button";
    import Cookie from 'js-cookie';
    import { querymemByIdInfo,updatememinfo } from '../api/index';
    export default {
        components: {
            ElButton,
            ElCard,
            ElImage
        },
        data () {
            return {
                activeName: 'first',
                objmem:{},
                user:'',
                form: {
                    memid:'',
                    deptname:'',
                    date1: "",
                    date2:"",
                    sendcity: "",
                    arrivalcity:"",
                },
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],

                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],

                }
            }
        },
        activated(){
            const user = Cookie.get('phoneKey');
            if (user) {
                this.user = user;
            }
            //根据手机号查询会员信息
            this.querymeminfobyid(user);
        },
        methods: {
            querymeminfobyid(phone){
                const params={mobilePhone:phone};
                const p = this.$querystring.stringify(params);
                querymemByIdInfo(p).then(res => {
                    this.objmem=res.data.data.ret[0];
                    this.form.deptname=this.objmem.deptName;
                    this.form.memid=this.objmem.memId;
                 })
            },
            //修改会员信息
            updatemem(){
                const deptinfo=this.form.deptname;
                if(""==deptinfo || null==deptinfo || undefined==deptinfo){
                    this.$message.warning('请输入部门');
                    return;
                }
                const params = {
                    mobilePhone: this.user,
                    deptName: deptinfo,
                    memId:this.form.memid
                }
                updatememinfo(params).then(res => {
                    if (res.data.code && res.data.code === 200) {

                    this.$message.success('修改成功');
                    this.form.deptname="";
                    this.$router.push('/gerenzx');
                }else{
                    var  msgstr=res.data.msg;
                    this.$message.error('修改失败:'+msgstr);

                }
            })
            }
        },
        name: "updateziliao-right"
    }
</script>

<style scoped>
    .savebtndiv{
        width: calc(88% - 0px - 10px) !important;
        margin-top:3vh;
        text-align: center;


    }
    .savebtn{
        background-color:#42B134;
        color:#FFFFFF;
        width:400px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .spanstyles{
        width:20vw;
        display: inline-block;
        margin-left:20px;
    }
    .tcontentwidth{
        width: calc(60% - 0px - 0px) !important;
    }
    .rightoper {
        margin-top: 30px;
    }
    .shuxian{
        height:180px;
        font-size:1px;
        width:1px;
        border-right:1px dashed rgba(66, 177, 52, 1);

        margin-left:30px;
    }
    .defaultbtn{
        background-color: #42B134;
        color:#FFFFFF;
    }
    .hcpdiv{
        margin-top:2vh;
        background-color:#F1F1F1;
        width:200px;
        padding-top:2vh;
        padding-left:1.6vw;
        border-top-right-radius: 20px;
        border-top-left-radius: 6px;

    }
    .titlecolor{

    }
    .xiancolor{
        color:rgba(66, 177, 52, 1);
    }
    .hcpicon{
        width:20px;
        height:27px;
    }
    .dfkcolor{
        color:rgba(100, 100, 100, 1);
    }
    .mleft{
        margin-left:16px;
    }
    .titlecolor{
        color:rgba(66, 177, 52, 1);
    }
    .mttop{
        margin-top:1vh;
    }
    .weiziiconst{
        width:24px;
        height:24px;
    }
    .inputstyle{
        width:10vw;
    }
    .cdiv{
        margin-top:30px;
    }
    .xiadandate{
        color:#646464;
        font-size:12px;
        margin-left:10vw;
    }
    .xiadaninfo{
        margin-left:1.8vw;
        margin-top:3vh;
        color:#646464;
        font-size:12px;

    }
    .mtopstyle{
        margin-left:1vw;
        margin-top:1vh;
    }
    .hengxian{
        border-bottom:1px solid rgba(34, 172, 56, 1);
        margin-left:2vw;
    }
    .paycolor{
        color:#42B134;
        border:1px solid #42B134;
        width:100px;
        height:35px;
        text-align: center;
    }
    .mstyle{
        width:100px;
        text-align:center;

    }
    .operbtn{
        margin-left:30px;
        margin-top:8vh;

    }
    .redcolorstyle{
        color:#FF7342;
        font-weight:bolder;
    }
    .bluecolor{
        color:#22AC38;
    }
    .sficon{
        margin-left:0.5vw;
    }
    .tabwidth{
        width: calc(88% - 0px - 10px) !important;
        background-color:#F1F1F1;
        margin-top:3vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .weiziiconsj{
        width:16px;
        height:16px;
        padding-left:1.2vw;
    }
    .iconleft{
        margin-left:0.5vw;
        margin-top:0.2vh;
    }
    .datestrs{
        margin-left:0.5vw;
    }
    .traindatediv{
        background-color:#F1F1F1;
        width: calc(88% - 0px - 10px) !important;
        border-top-right-radius: 20px;
        padding-top:20px;

    }
    .copyright{
        width: calc(88% - 0px - 10px) !important;
        font-size:12px;
        text-align: center;
        margin-top:5vh;
        color:#B6B6B6;
    }
    .mleftmargin{
        margin-left:1vw;
    }
    .pager{
        margin-top:5vh;
        margin-left:0vw;
        width: calc(88% - 0px - 10px) !important;
        display: flex;
        justify-content: center;

    }
    .shouye{

    }
    .sybtn{
        width:100px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .btnnodiv{
        margin-left:1vw;
    }
    .btnno{
        width:50px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }

    .commonmtop{
        margin-top:2vh;
    }
    .btnmargin{
        margin-top:1vh;
    }
    .btnstyle{
        width:100px;
        height:35px;
        background-color: #42B134;
        color:#FFFFFF;
        border-radius: 5px;
    }

    .checizuowei{
        margin-left:5vw;
    }
    .colorstyle{
        color:#F49200;
        font-weight: bolder;
    }
    .checileixing{
        margin-left:5vw;
    }
    .checimoney{
        margin-left:5vw;
    }
    .zwleixing{
        margin-top:0.1vh;
    }
    .cmtopzw{
        margin-top:2vh;
    }
    .cxinfo{
        margin-left:2vw;
    }
    .endcheci{
        margin-left:3vw;
    }
    .sleft{
        margin-left:1vw;
    }
    .scheci{
        margin-left:2vw;

    }
    .startzhan{
        margin-left:1vw;
    }
    .scdiv{
        margin-top:2vh;
    }
    .sccdate{
        font-size:20px;
        font-weight: bolder;
        margin-left:1vw;
    }
    .haoshi{
        margin-left:3vw;
    }
    .tcontent{
        background-color:#F1F1F1;
        height:180px;
        width: calc(88% - 0px - 10px) !important;
        margin-top:-1vh;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .mleftd{
        margin-left:-2vw;
    }
    .querybtn{
        background-color:#42B134;
        color:#FFFFFF;
        border-radius: 5px;
        margin-left:1vw;
        width:100px;
    }
    .trainorderstyle{
        margin-left:1vw;
    }
    .formstyle{
        padding-top:2.2vh;
    }
    .container{

    }
    .header{
        font-size:12px;
    }
    .flex{
        display:flex;
    }
    .weiziicon{

    }
    .pleft{
        margin-left:0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }
    .hc{
        background-color: #F1F1F1;
        width: calc(88% - 0px - 10px) !important;
        height:130px;
        margin-top:2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .formstylejp{
        padding-top:2.2vh;
    }
    button:hover {background-color: #42B134;color:#FFFFFF;}
</style>