<template>
  <el-row>
    <el-col>
  <el-menu
    class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
    :collapse="isCollapse"
    background-color="#EFEFEF"
    text-color="#000000"
    active-text-color="#42B134"
    :default-active="this.$route.name"
  >
    <!-- <h2>机票系统</h2> -->
    <!-- <el-button icon="el-icon-s-operation"></el-button> -->
    <!-- <i :class="isCollapse ?'el-icon-s-unfold' : 'el-icon-s-fold'" id="acticon" @click="isCollapse = !isCollapse"></i> -->
    <el-menu-item
      v-for="item in noHasChildrenMenu"
      :key="item.name"
      :index="item.name"
      @click="clickMenu(item)"
      style="border-bottom:1px solid #AAAAAA;font-famliy:MicrosoftYaHei;"
    >
      <!--
      <i :class="`el-icon-${item.icon}`"></i>
      -->
      <el-image v-if="$route.name==item.name" class="iconstyle" :src="item.iconActive"
                fit="cover"></el-image>
      <el-image v-else class="iconstyle" :src="item.icon"
                fit="cover"></el-image>
      <span slot="title" class="mleft fweight" v-if="$route.name==item.name" >{{ item.label }}</span>
      <span slot="title" class="mleft " v-else>{{ item.label }}</span>
    </el-menu-item>
    <el-submenu
      v-for="subItem in hasChildrenMenu"
      :key="subItem.name"
      :index="subItem.name"
    >
      <template slot="title">
        <i :class="`el-icon-${subItem.icon}`"></i>
        <span>{{ subItem.label }}</span>
      </template>
      <el-menu-item-group
        v-for="ssItem in subItem.children"
        :key="ssItem.name"
        :index="ssItem.name"
      >
        <el-menu-item @click="clickMenu(ssItem)" :index="ssItem.name">{{
          ssItem.label
        }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
    </el-col>
  </el-row>
</template>
    <script>
export default {
  data () {
    return {
      menuData: [
        {
          path: '/pageindex',
          name: "pageindex",
          label: '首页',
            icon:require("@/assets/img/shouye.png"),
            iconActive:require("@/assets/img/shouye_color.png"),
          url: '',
        },
        {
          path: '/trainyuding',
          name: "trainyuding",
          label: '火车票预订',
            icon:require("@/assets/img/huochepiao.png"),
            iconActive:require("@/assets/img/huochepiao_color.png"),
          url: '',
        },
          {
              path: '/airticketyuding',
              name: "airticketyuding",
              label: '机票预订',
              icon:require("@/assets/img/jipiao.png"),
              iconActive:require("@/assets/img/jipiao_color.png"),
              url: '',
          },
          {
              path: '/trainorder',
              name: "trainorder",
              label: '火车票订单',
              icon:require("@/assets/img/huochedingdan.png"),
              iconActive:require("@/assets/img/huochedingdan_color.png"),
              url: '',
          },
          {
              path: '/airticketorder',
              name: "airticketorder",
              label: '机票订单',
              icon:require("@/assets/img/jipiaodingdan.png"),
              iconActive:require("@/assets/img/jipiaodingdan_color.png"),
              url: '',
          },
          {
              path: '/accountmingxi',
              name: "accountmingxi",
              label: '账户明细',
              icon:require("@/assets/img/zhanghu.png"),
              iconActive:require("@/assets/img/zhanghu_color.png"),
              url: '',
          },
          {
              path: '/passenger',
              name: "passenger",
              label: '常用旅客',
              icon:require("@/assets/img/lvke.png"),
              iconActive:require("@/assets/img/lvke_color.png"),
              url: '',
          },
          {
              path: '/gerenzx',
              name: "gerenzx",
              label: '个人中心',
              icon:require("@/assets/img/gerenzhongxin.png"),
              iconActive:require("@/assets/img/gerenzhongxin_color.png"),
              url: '',
          }

      ],
    }
  },

  methods: {

    handleOpen (key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose (key, keyPath) {
      //console.log(key, keyPath);
    },
    clickMenu (item) {
          if (this.$route.path !== item.path && !(this.$route.path === '/home' && (item.path === '/'))) {
            this.$router.push(item.path);
          }
    }
  },
  computed: {
    hasChildrenMenu () {
      return this.menuData.filter(item => item.children);
    },
    noHasChildrenMenu () {
        //console.log(this.menuData);
      return this.menuData.filter(item => !item.children);
    },
    isCollapse () {
        return this.$store.state.tab.isCollapse;
    }

  },
}
</script>

<style lang="less" scoped>
  .el-menu-item .is-active{
    color:#42B134;
  }
  .el-menu-vertical-demo{


  }
  .mleft{
    margin-left:0.8vw;
  }
  .iconstyle{
    width:25px;
    height:25px;
  }
#acticon {
  font-size: 26px;
  color: #fff;
  cursor: pointer;
  margin: 0 0 0 20px;
}
.fweight{
  font-family: MicrosoftYaHei;
  font-weight: bold;
}
.el-menu {
  min-height: 100vh;
  border: none;

  font-family: MicrosoftYaHei;
  font-size: 18px;
  // color: #42B134;
  // margin-top:4.6vh;
  width:222px;
  h2 {
    color: #fff;
    text-align: center;
    line-height: 48px;
  }
}

</style>