<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>机票订单</span>
            </div>
        </div>
        <div class="hc">

            <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstylejp">

                <el-form-item label="下单日期" class="mttop" prop="ordertime">
                    <el-col :span="24">
                        <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.ordertime"
                            class="inputstyle"></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="出发日期" class="mttop" prop="senddate">
                    <el-col :span="24">
                        <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.senddate"
                            class="inputstyle"></el-date-picker>
                    </el-col>
                </el-form-item>

                <el-form-item label="下单人" class="mttop" prop="createperson">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.createperson"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="流水号" class="mttop" prop="liushuiNo">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.liushuiNo"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="乘客姓名" class="mttop" prop="xingming">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.xingming"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="机票票号" class="mttop" prop="tkno">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.tkno"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="航班号" class="mttop" prop="flightNumber">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.flightNumber"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="出发" class="mttop" prop="departurestation">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.departurestation"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="所属单位" class="mttop" prop="danweiName">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.danweiName"
                                  clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="到达" class="mttop" prop="arrivalstation">
                    <el-col :span="24">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.arrivalstation"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="订单状态" class="mttop" prop="ticketstatus">
                    <el-col :span="24">
                        <el-select class="inputstyle" v-model="form.ticketstatus" placeholder="订单状态">
                            <!-- <el-option label="全部" value="0"></el-option>
                            <el-option label="有效" value="1"></el-option> -->
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="item in Object.keys(ticketTypeDic)" :key="item"
                                :label="ticketTypeDic[item]" :value="item">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form-item>

                <el-form-item class="mttop">
                    <el-col :span="2">
                        <el-button class="querybtn" @click="search">搜索</el-button>
                    </el-col>
                </el-form-item>
            </el-form>


        </div>


        <template v-for="(item, itemIndex) in list">
            <div class="hcpdiv flex" :key="item.aOrderId + 'a'" v-if="item.xcList.length">
                <div>
                    <el-image class="hcpicon" :src="require('../assets/img/feiji.png')" fit="cover"></el-image>
                </div>
                <div class="titlecolor mleft">
                    飞机票
                </div>
                <div class="mleft xiancolor">
                    |
                </div>
                <div class="mleft dfkcolor">
                    {{ item.refundStatus === '3' ? '已退票' : item.cancelStatus === '5' ? '已取消'
                        : ticketTypeDic[item.ticketStatus] }}

                </div>
            </div>
            <div class="tcontent flex" :key="item.aOrderId + 'b'" v-if="item.xcList.length">
                <div class="tcontentwidth">
                    <div class="traindatediv flex">
                        <div class="iconleft">
                            <el-image class="weiziiconsj" :src="require('../assets/img/shijian.png')"
                                fit="cover"></el-image>
                        </div>
                        <div class="datestrs">{{ item.xcList[dt[itemIndex]].sendDate }}
                            <span class="danweistyle" v-if="item.danweiName!=null && item.danweiName!=''"> 所属单位：{{ item.danweiName}}</span>
                        </div>

                    </div>
                    <div class="flex">
                        <div class="scheci commonmtop">
                            <div class="flex">
                                <div class="sccdate">
                                    {{ item.xcList[dt[itemIndex]].sendCity }}
                                </div>
                                <div class="sficon">
                                    <el-image class="weiziicon" :src="require('../assets/img/shi.png')"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="scdiv flex">

                                <div class="startzhan bluecolor">{{ item.xcList[dt[itemIndex]].sendDate }}</div>
                            </div>
                        </div>
                        <div class="haoshi commonmtop">
                            <div class="cxinfo">{{ item.xcList[dt[itemIndex]].flightNumber }}</div>
                            <div>
                                <el-image class="weiziicon" :src="require('../assets/img/jiantou.png')"
                                    fit="cover"></el-image>
                            </div>
                            <div class="sleft">{{ item.xcList[dt[itemIndex]].flightHours }}</div>
                        </div>
                        <div class="endcheci commonmtop">
                            <div class="flex">
                                <div class="sccdate">
                                    {{ item.xcList[dt[itemIndex]].arrivalCity }}
                                </div>
                                <div class="sficon">
                                    <el-image class="weiziicon" :src="require('../assets/img/zhong.png')"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="scdiv flex">
                                <div class="startzhan bluecolor">{{ item.xcList[dt[itemIndex]].arrivalDate }}</div>
                            </div>
                        </div>

                        <div>
                        </div>

                    </div>

                    <div class="xingcheng flex" v-if="item.xcList.length > 1">
                        <el-button v-for="(childItem, index) in item.xcList" :key="index"
                            :class="dt[itemIndex] == index ? 'firstxcbtn' : 'xingchengbtn'"
                            @click="gotoxcone(index, itemIndex)">行程{{ index + 1 }}</el-button>
                    </div>
                    <div class="hengxian">

                    </div>
                    <div class="xiadaninfo flex">
                        <div style="margin-right:10px;">
                            下单人：<span>{{ item.createPerson }}</span>
                        </div>
                        <div>
                            共：<span>{{ item.pgList.length }}人</span>
                        </div>
                        <div class="xiadandate">
                            下单时间：{{ item.createTime }}
                        </div>
                    </div>
                </div>
                <div class="shuxian">

                </div>
                <div class="rightoper">
                    <div class="redcolorstyle mstyle">
                        {{ item.orderMoney ? `￥${item.orderMoney}` : '-' }}
                    </div>
                    <div class="operbtn flex" style="margin-top:10vh;">
                        <el-button class="sybtn" @click="gotoAirtkOrderdetails(item)">详情</el-button>
                        <el-button v-if="item.cancelStatus !== '5' && item.refundStatus !== '3'"
                            class="paycolor">支付</el-button>
                        <el-button v-if="item.cancelStatus !== '5' && item.refundStatus !== '3'"
                            class="sybtn" @click="handelCancle(item)">取消</el-button>
                    </div>
                </div>
            </div>
        </template>

        <el-empty class="empty-content" v-if="list.length == 0" :description="loading ? '正在加载' : '暂无数据'"></el-empty>


        <div class="pager flex">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                :total="pagination.total"></el-pagination>
        </div>
        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
    </div>
</template>

<script>
    import Cookie from 'js-cookie';
import ElImage from "element-ui/packages/image/src/main";
import ElButton from "element-ui/packages/button/src/button";
import { queryAirtorderpage, updateairorderstatus } from "@/api";
export default {
    components: {
        ElButton,
        ElImage
    },
    data() {
        return {
            form:{
                ordertime:'',
                senddate:'',
                createperson:'',
                liushuiNo:'',
                xingming:'',
                tkno:'',
                flightNumber:'',
                departurestation:'',
                danweiName:'',
                arrivalstation:'',
                ticketstatus:'',
            },
            sendcity1: '',
            arrivalcity1: '',
            sendate1: '',
            arrivaldate1: '',
            hours1: '',
            flight1: '',
            sendcity2: '',
            arrivalcity2: '',
            sendate2: '',
            arrivaldate2: '',
            hours2: '',
            flight2: '',
            sendcity3: '',
            arrivalcity3: '',
            sendate3: '',
            arrivaldate3: '',
            hours3: '',
            flight3: '',
            sendcity4: '',
            arrivalcity4: '',
            sendate4: '',
            arrivaldate4: '',
            hours4: '',
            flight4: '',
            sendcity5: '',
            arrivalcity5: '',
            sendate5: '',
            arrivaldate5: '',
            hours5: '',
            flight5: '',
            sendcity6: '',
            arrivalcity6: '',
            sendate6: '',
            arrivaldate6: '',
            hours6: '',
            flight6: '',
            dt: [],
            xc1: false,
            xc2: false,
            xc3: false,
            xc4: false,
            activeName: 'first',
            form: {},
            rules: {
                mobilephone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                ],

                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],

            },
            ticketTypeDic: {
                1: '待出票',
                2: '出票中',
                3: '未完成',
                4: '已完成',
                5: '待支付',
            },
            pagination: {
                curpage: 1,
                pagesize: 10,
                total: 0
            },
            list: [],
            loading: false
        }
    },

    name: "airticketorder-right",
    created() {
        this.xc1 = true;
        this.xc2 = false;
        this.xc3 = false;
        this.xc4 = false;
        this.sendcity1 = '首都T3';
        this.arrivalcity1 = '新郑T2';
        this.sendate1 = '06:30';
        this.arrivaldate1 = '11:58';
        this.hours1 = '6小时20分';
        this.flight1 = '东航MU0110';
        // this.fetchData()
    },
    activated() {
        const phonestrs=Cookie.get('phoneKey');
        if(null!=phonestrs && ""!=phonestrs && undefined!=phonestrs){
            this.form.createperson=phonestrs;
        }
        this.fetchData()
    },
    methods: {
        handleCurrentChange(val) {
            this.pagination.curpage = val;
            this.fetchData();
        },
        search() {
            this.pagination.curpage = 1
            this.fetchData()
        },
        async fetchData() {
            try {
                this.loading = false
                const data = await queryAirtorderpage({ ...this.pagination, ...this.form })
                //console.log("%c Line:298 🍻 data", "color:#6ec1c2", data.data.data);
                this.list = data.data.data.data;
                this.pagination.total = data.data.data.total
                this.dt = new Array(this.list.length).fill(0)
            } catch (error) {
                this.list = []
            } finally {
                this.loading = false
            }

        },
        //行程1
        gotoxcone(index, itemIndex) {
            this.dt[itemIndex] = index
            this.$forceUpdate()
        },
        //行程2
        //转向机票第二个详情路由
        gotoAirtkOrderdetailstwo(item) {
            this.$router.push('/airticketorderdetailstwo?id=' + item.aOrderId);
        },
        //转向机票订单详情路由
        gotoAirtkOrderdetails(item) {
            //获取退票状态
            const refundstatus=item.refundStatus;
            const changestatus=item.changeStatus;
            //退票
            if(3==refundstatus || 3==changestatus){
                this.$router.push('/airticketgaiqiantuipiao?id=' + item.aOrderId);
            }else {
                this.$router.push('/airticketorderdetails?id=' + item.aOrderId);
            }
        },
        async handelCancle(item) {
            this.$confirm("是否确认取消订单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    const data = await updateairorderstatus({
                        aOrderId: item.aOrderId,
                        cancelStatus: 5,
                    });
                    if (data.data.code === 200) {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.fetchData()
                    }
                })
                .catch(() => { });
        },
    }
}
</script>

<style scoped>
    .danweistyle{
        font-size:13px;
        margin-top:2px;
    }
.tpinfo /deep/ .el-card {
    border: 1px solid #FFFFFF;
}

.tpinfo /deep/ .el-dialog__body {
    padding: 0;
    color: #606266;
    font-size: 14px;
    /*word-break: break-all;*/
}

.tcontentwidth {
    width: calc(60% - 0px - 0px) !important;
}

.shuxian {
    height: 220px;
    font-size: 1px;
    width: 1px;
    border-right: 1px dashed rgba(66, 177, 52, 1);

    margin-left: 30px;
}

.mtopstyle {
    margin-left: 1vw;
    margin-top: 1vh;
}

.hengxian {
    border-bottom: 1px solid rgba(34, 172, 56, 1);
    margin-left: 2vw;
    margin-top: 1vh;
}

.defaultbtn {
    background-color: #42B134;
    color: #FFFFFF;
}

.hcpdiv {
    margin-top: 2vh;
    background-color: #F1F1F1;
    width: 200px;
    padding-top: 2vh;
    padding-left: 1.6vw;
    border-top-right-radius: 20px;
    border-top-left-radius: 6px;

}

.titlecolor {}

.hcpicon {
    width: 20px;
    height: 27px;
}

.dfkcolor {
    color: rgba(100, 100, 100, 1);
}

.mleft {
    margin-left: 16px;
}

.titlecolor {
    color: rgba(66, 177, 52, 1);
}

.mttop {
    margin-top: 1vh;
}

.weiziiconst {
    width: 24px;
    height: 24px;
}

.weiziiconsj {
    width: 16px;
    height: 16px;
    padding-left: 1.2vw;
}

.cdiv {}

.xingcheng {
    margin-left: 1vw;
    margin-top: 2vh;
}

.firstxcbtn {
    width: 80px;
    height: 35px;
    background: rgba(66, 177, 52, 0.56);
    color: #000000;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.xingchengbtn {
    width: 80px;
    height: 35px;
    background: rgba(66, 177, 52, 0.17);
    color: #000000;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

.xiadandate {
    color: #646464;
    font-size: 12px;
    margin-left: 10vw;
}

.xiadaninfo {
    margin-left: 1.8vw;
    margin-top: 1.5vh;
    color: #646464;
    font-size: 12px;

}

.mtopstyle {
    margin-left: 1vw;
    margin-top: 1vh;
}


.paycolor {
    color: #42B134;
    border: 1px solid #42B134;
    width: 100px;
    height: 35px;
    text-align: center;
}

.mstyle {
    width: 100px;
    text-align: center;
}

.operbtn {
    margin-left: 30px;
    margin-top: 8vh;
}

.rightoper {
    margin-top: 30px;
}

.redcolorstyle {
    color: #FF7342;
    font-weight: bolder;
}

.bluecolor {
    color: #22AC38;
}

.sficon {
    margin-left: 0.5vw;
}

.tabwidth {
    width: calc(88% - 0px - 10px) !important;
    background-color: #F1F1F1;
    margin-top: 3vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.iconleft {
    margin-left: 0.5vw;
    margin-top: 0.2vh;
}

.datestrs {
    margin-left: 0.5vw;
}

.traindatediv {
    background-color: #F1F1F1;
    width: calc(88% - 0px - 10px) !important;
    border-top-right-radius: 20px;
    padding-top: 20px;
}

.copyright {
    width: calc(88% - 0px - 10px) !important;
    font-size: 12px;
    text-align: center;
    margin-top: 5vh;
    color: #B6B6B6;
}

.mleftmargin {
    margin-left: 1vw;
}

.pager {
    margin-top: 5vh;
    margin-left: 0vw;
    width: calc(88% - 0px - 10px) !important;
    display: flex;
    justify-content: center;
}

.shouye {}

.sybtn {
    width: 100px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.btnnodiv {
    margin-left: 1vw;
}

.btnno {
    width: 50px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.commonmtop {
    margin-top: 2vh;
}

.btnmargin {
    margin-top: 1vh;
}

.btnstyle {
    width: 100px;
    height: 35px;
    background-color: #42B134;
    color: #FFFFFF;
    border-radius: 5px;
}

.checizuowei {
    margin-left: 5vw;
}

.colorstyle {
    color: #F49200;
    font-weight: bolder;
}

.checileixing {
    margin-left: 5vw;
}

.checimoney {
    margin-left: 5vw;
}

.zwleixing {
    margin-top: 0.1vh;
}

.cmtopzw {
    margin-top: 2vh;
}

.cxinfo {
    margin-left: 2px;
}

.endcheci {
    margin-left: 3vw;
}

.sleft {
    margin-left: 1vw;
}

.scheci {
    margin-left: 2vw;

}

.startzhan {
    margin-left: 1vw;
}

.scdiv {
    margin-top: 2vh;
}

.sccdate {
    font-size: 20px;
    font-weight: bolder;
    margin-left: 1vw;
}

.haoshi {
    margin-left: 3vw;
}

.tcontent {
    background-color: #F1F1F1;
    height: 220px;
    width: calc(88% - 0px - 10px) !important;
    margin-top: -1vh;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.xiancolor {
    color: rgba(66, 177, 52, 1);
}

.mleftd {
    margin-left: -2vw;
}

.querybtn {
    background-color: #42B134;
    color: #FFFFFF;
    border-radius: 5px;
    margin-left: 1vw;
    width: 100px;
}

.inputstyle {
    width: 10vw;
}

.formstylejp {
    padding-top: 2.2vh;
}

.container {}

.header {
    font-size: 12px;
}

.flex {
    display: flex;
}

.weiziicon {}

.pleft {
    margin-left: 0.5vw;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.hc {
    background-color: #F1F1F1;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

button:hover {
    background-color: #42B134;
    color: #FFFFFF;
}

.empty-content {
    width: calc(88% - 0px - 10px) !important;
}
</style>
