<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">当前位置：<span>首页</span><span>>火车票订单</span></div>
        </div>
        <div class="hc">
            <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstylejp">
                <el-form-item label="下单日期" class="mttop" prop="date1">
                    <el-col :span="20">
                        <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                            v-model="form.ordertime" class="inputstyle"></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="出发日期" class="mttop" prop="date2">
                    <el-col :span="20">
                        <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.senddate"
                            class="inputstyle"></el-date-picker>
                    </el-col>
                </el-form-item>

                <el-form-item label="下单人" class="mttop" prop="sendcity">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.createperson"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="流水号" class="mttop" prop="sendcity">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.liushuiNo"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="乘客姓名" class="mttop" prop="sendcity">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.xingming"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="火车票号" class="mttop" prop="sendcity">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.tkno"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="车次号" class="mttop" prop="sendcity">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.trainNumber"
                            clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="出发" class="mttop" prop="departurestation">
                    <el-col :span="20">
                        <el-select class="inputstyle heightstyle" v-model="form.departurestation" filterable remote
                            reserve-keyword placeholder="请输入关键词"
                            :remote-method="(value) => remoteMethod(value, 'startStationOptions')" :loading="loading">
                            <el-option v-for="item in startStationOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <!-- <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.sendcity"
                            clearable></el-input> -->
                    </el-col>
                </el-form-item>
                <el-form-item label="到达" class="mttop" prop="arrivalstation">
                    <el-col :span="20">
                        <el-select class="inputstyle heightstyle" v-model="form.arrivalstation" filterable remote
                            reserve-keyword placeholder="请输入关键词"
                            :remote-method="(value) => remoteMethod(value, 'endStationOptions')" :loading="loading">
                            <el-option v-for="item in endStationOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <!-- <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.sendcity"
                            clearable></el-input> -->
                    </el-col>
                </el-form-item>
                <el-form-item label="所属单位" class="mttop" prop="danweiName">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.danweiName"
                                  clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="订单状态" class="mttop" prop="ticketstatus">
                    <el-col :span="20">
                        <el-select class="inputstyle" v-model="form.ticketstatus" placeholder="订单状态">
                            <el-option label="全部" value=""></el-option>
                            <!-- <el-option label="有效" value="1"></el-option> -->
                            <el-option v-for="item in Object.keys(ticketTypeDic)" :key="item"
                                :label="ticketTypeDic[item]" :value="item">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form-item>

                <el-form-item class="mttop">
                    <el-col :span="2">
                        <el-button class="querybtn" @click="search">搜索</el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
        <template v-for="(item, index) in list">
            <div class="hcpdiv flex" :key="index">
                <div>
                    <el-image class="hcpicon" :src="require('../assets/img/hc.png')" fit="cover"></el-image>
                </div>
                <div class="titlecolor mleft">火车票</div>
                <div class="mleft xiancolor">|</div>
                <div class="mleft dfkcolor">{{ item.refundStatus === '3' ? '已退票' : item.cancelStatus === '5' ? '已取消' :ticketTypeDic[item.ticketStatus] }}</div>
            </div>

            <div class="tcontent flex" :key="index">
                <div class="tcontentwidth">
                    <div class="traindatediv flex">
                        <div class="iconleft">
                            <el-image class="weiziiconsj" :src="require('../assets/img/shijian.png')"
                                fit="cover"></el-image>
                        </div>
                        <div class="datestrs">
                            {{ item.sendDate ? item.sendDate.split(" ")[0] : "" }}
                        </div>
                        <div class="datestrs danweistyle" v-if="item.danweiName!=null && item.danweiName!=''">
                            所属单位：<span>{{item.danweiName}}</span>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="scheci commonmtop">
                            <div class="flex">
                                <div class="sccdate">{{ item.departureStation }}</div>
                                <div class="sficon">
                                    <el-image class="weiziicon" :src="require('../assets/img/shi.png')"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="scdiv flex">
                                <div class="startzhan bluecolor">
                                    {{ item.sendDate ? item.sendDate.split(" ")[1] : "" }}
                                </div>
                            </div>
                        </div>
                        <div class="haoshi commonmtop">
                            <div class="cxinfo">{{ item.trainNumber }}</div>
                            <div>
                                <el-image class="weiziicon" :src="require('../assets/img/jiantou.png')"
                                    fit="cover"></el-image>
                            </div>
                            <div class="sleft">{{ item.runtime }}</div>
                        </div>
                        <div class="endcheci commonmtop">
                            <div class="flex">
                                <div class="sccdate">{{ item.arrivalStation }}</div>
                                <div class="sficon">
                                    <el-image class="weiziicon" :src="require('../assets/img/zhong.png')"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="scdiv flex">
                                <div class="startzhan bluecolor">
                                    {{ item.arrivalDate ? item.arrivalDate.split(" ")[1] : "" }}
                                </div>
                            </div>
                        </div>

                        <div></div>
                    </div>
                    <div class="hengxian mtopstyle"></div>
                    <div class="xiadaninfo flex">

                        <div style="margin-right:10px;">
                            下单人：<span>{{ item.createPerson }}</span>
                        </div>
                        <div>
                            共：<span>{{ item.tkNum }}人</span>
                        </div>
                        <div class="xiadandate">下单时间：{{ item.orderTime }}</div>
                    </div>
                </div>
                <div class="shuxian"></div>
                <div class="rightoper">
                    <div class="redcolorstyle mstyle">￥{{ item.orderMoney }}</div>
                    <div class="operbtn flex">
                        <el-button class="sybtn" @click="() => forwardtrainorderdetails(item.tOrderId,item)">详情</el-button>
                        <el-button v-if="item.cancelStatus !== '5' && item.refundStatus !== '3'"
                                   class="paycolor">支付</el-button>
                        <el-button class="sybtn" v-if="item.cancelStatus !== '5' && item.refundStatus !== '3'" @click="() => handelCancle(item)">取消</el-button>
                    </div>
                </div>
            </div>
        </template>

        <div class="pager flex">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                :total="pagination.total"></el-pagination>
        </div>
        <div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
    </div>
</template>

<script>
    import Cookie from 'js-cookie';
import ElImage from "element-ui/packages/image/src/main";
import ElButton from "element-ui/packages/button/src/button";
import { querytorderpage, querytrainlike, updateorderstatus } from "@/api";
// import { search } from "core-js/fn/symbol";
export default {
    components: {
        ElButton,
        ElImage,
    },
    data() {
        return {
            activeName: "first",
            form: {
                liushuiNo:"",
                createperson:"",
                ordertime: "",
                date2: "",
                departurestation: "",
                arrivalstation: "",
                danweiName:"",
                trainNumber:"",
                tkno:"",
            },
            rules: {
                mobilephone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
                ],

                pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            list: [],
            pagination: {
                curpage: 1,
                pagesize: 10,
                total: 0,
            },
            // 1 待出票  2出票中 3 未完成 4 已完成
            ticketTypeDic: {
                1: "待出票",
                2: "出票中",
                3: "未完成",
                4: "已完成",
                5: "待支付",
            },
            startStationOptions: [],
            endStationOptions: [],
            loading: false,
        };
    },
    created() { },
    activated() {
        const phonestrs=Cookie.get('phoneKey');
        if(null!=phonestrs && ""!=phonestrs && undefined!=phonestrs){
            this.form.createperson=phonestrs;
        }
        this.getOrderList();
    },
    methods: {
        debounce(func, wait, immediate) {
            let timeout;
            return (function () {
                let context = this,
                    args = arguments;
                let later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            })();
        },
        remoteMethod(query, type = "startStationOptions") {
            this.debounce(() => {
                if (query !== "") {
                    this.loading = true;
                    querytrainlike({ stationName: query }).then((res) => {
                        // console.log("%c Line:273 🍩", "color:#4fff4B", res);
                        const { status, data } = res;
                        if (status == 200) {
                            console.log("%c Line:277 🥐", "color:#b03734", data, type);
                            this[type] = data.map((item) => {
                                return { value: item.stationName, label: item.stationName };
                            });
                        }
                        this.loading = false;
                    });
                    // setTimeout(() => {
                    //     this.loading = false;
                    //     this.options = this.list.filter((item) => {
                    //         return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    //     });
                    // });
                } else {
                    this.options = [];
                }
            }, 500);
        },
        handleCurrentChange(val) {
            this.pagination.curpage = val;
            this.getOrderList();
        },
        search() {
            this.pagination.curpage = 1;
            this.getOrderList();
        },
        async getOrderList() {
            try {
                const data = await querytorderpage({ ...this.pagination, ...this.form });
                this.list = data.data.data.data;
                this.pagination.total = data.data.data.total;
            } catch (error) {
                this.list = [];
            }
        },
        //转向火车票详情第二个页面
        forwardtorderdetails() {
            this.$router.push("/trainorderdetailstwo");
        },
        //转向火车订单详情
        forwardtrainorderdetails(orderId,item) {
            //看是否退票或者改签
            const refundstatus=item.refundStatus;
            const changestatus=item.changeStatus;
            //退票
            if(3==refundstatus || 3==changestatus){
                this.$router.push("/traingaiqiantuipiao?orderId=" + orderId);
            }else{
                this.$router.push("/trainorderdetails?orderId=" + orderId);
            }
        },
        async handelCancle(item) {
            this.$confirm("是否确认取消订单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                const data = await updateorderstatus({
                    tOrderId: item.tOrderId,
                    cancelStatus: 5,
                });
                if(data.data.code === 200){
                    this.$message({
                        type: "success",
                        message: "操作成功!",
                    });
                    this.getOrderList();
                }
            }).catch(() => {
                
            });
        },
    },
    name: "trainorder-right",
};
</script>

<style scoped>
    .danweistyle{
        font-size:13px;
        margin-top:2px;
    }
.tcontentwidth {
    width: calc(60% - 0px - 0px) !important;
}

.rightoper {
    margin-top: 30px;
}

.shuxian {
    height: 190px;
    font-size: 1px;
    width: 1px;
    border-right: 1px dashed rgba(66, 177, 52, 1);

    margin-left: 30px;
}

.defaultbtn {
    background-color: #42b134;
    color: #ffffff;
}

.hcpdiv {
    margin-top: 2vh;
    background-color: #f1f1f1;
    width: 200px;
    padding-top: 2vh;
    padding-left: 1.6vw;
    border-top-right-radius: 20px;
    border-top-left-radius: 6px;
}

.titlecolor {}

.xiancolor {
    color: rgba(66, 177, 52, 1);
}

.hcpicon {
    width: 20px;
    height: 27px;
}

.dfkcolor {
    color: rgba(100, 100, 100, 1);
}

.mleft {
    margin-left: 16px;
}

.titlecolor {
    color: rgba(66, 177, 52, 1);
}

.mttop {
    margin-top: 1vh;
}

.weiziiconst {
    width: 24px;
    height: 24px;
}

.inputstyle {
    width: 10vw;
}

.cdiv {}

.xiadandate {
    color: #646464;
    font-size: 12px;
    margin-left: 10vw;
}

.xiadaninfo {
    margin-left: 1.8vw;
    margin-top: 3vh;
    color: #646464;
    font-size: 12px;
}

.mtopstyle {
    margin-left: 1vw;
    margin-top: 1vh;
}

.hengxian {
    border-bottom: 1px solid rgba(34, 172, 56, 1);
    margin-left: 2vw;
}

.paycolor {
    color: #42b134;
    border: 1px solid #42b134;
    width: 100px;
    height: 35px;
    text-align: center;
}

.mstyle {
    width: 100px;
    text-align: center;
}

.operbtn {
    margin-left: 30px;
    margin-top: 8vh;
}

.redcolorstyle {
    color: #ff7342;
    font-weight: bolder;
}

.bluecolor {
    color: #22ac38;
}

.sficon {
    margin-left: 0.5vw;
}

.tabwidth {
    width: calc(88% - 0px - 10px) !important;
    background-color: #f1f1f1;
    margin-top: 3vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.weiziiconsj {
    width: 16px;
    height: 16px;
    padding-left: 1.2vw;
}

.iconleft {
    margin-left: 0.5vw;
    margin-top: 0.2vh;
}

.datestrs {
    margin-left: 0.5vw;
}

.traindatediv {
    background-color: #f1f1f1;
    width: calc(88% - 0px - 10px) !important;
    border-top-right-radius: 20px;
    padding-top: 20px;
}

.copyright {
    width: calc(88% - 0px - 10px) !important;
    font-size: 12px;
    text-align: center;
    margin-top: 5vh;
    color: #b6b6b6;
}

.mleftmargin {
    margin-left: 1vw;
}

.pager {
    margin-top: 5vh;
    margin-left: 0vw;
    width: calc(88% - 0px - 10px) !important;
    display: flex;
    justify-content: center;
}

.shouye {}

.sybtn {
    width: 100px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.btnnodiv {
    margin-left: 1vw;
}

.btnno {
    width: 50px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.commonmtop {
    margin-top: 2vh;
}

.btnmargin {
    margin-top: 1vh;
}

.btnstyle {
    width: 100px;
    height: 35px;
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
}

.checizuowei {
    margin-left: 5vw;
}

.colorstyle {
    color: #f49200;
    font-weight: bolder;
}

.checileixing {
    margin-left: 5vw;
}

.checimoney {
    margin-left: 5vw;
}

.zwleixing {
    margin-top: 0.1vh;
}

.cmtopzw {
    margin-top: 2vh;
}

.cxinfo {
    margin-left: 2vw;
}

.endcheci {
    margin-left: 3vw;
}

.sleft {
    margin-left: 1vw;
    text-align: center;
}

.scheci {
    margin-left: 2vw;
}

.startzhan {
    margin-left: 1vw;
}

.scdiv {
    margin-top: 2vh;
}

.sccdate {
    font-size: 20px;
    font-weight: bolder;
    margin-left: 1vw;
}

.haoshi {
    margin-left: 3vw;
}

.tcontent {
    background-color: #f1f1f1;
    height: 190px;
    width: calc(88% - 0px - 10px) !important;
    margin-top: -1vh;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.mleftd {
    margin-left: -2vw;
}

.querybtn {
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
    margin-left: 1vw;
    width: 100px;
}

.trainorderstyle {
    margin-left: 1vw;
}

.formstyle {
    padding-top: 2.2vh;
}

.container {}

.header {
    font-size: 12px;
}

.flex {
    display: flex;
}

.weiziicon {}

.pleft {
    margin-left: 0.5vw;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.hc {
    background-color: #f1f1f1;
    width: calc(88% - 0px - 10px) !important;
    height: 210px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.formstylejp {
    padding-top: 2.2vh;
}

button:hover {
    background-color: #42b134;
    color: #ffffff;
}
</style>
